.rpm-input--control {
  --scale: scale(1);

  display: var(--input-display, grid);
  grid-gap: var(--input-grid-gap, var(--space-min));
  grid-template-columns: var(--input-grid-columns, auto 1fr);
  align-items: var(--input-align-items, baseline);
  transition: var(--input-timing) ease-out;
  color: var(--color, inherit);
  cursor: pointer;

  &:not(.is-disabled) {
    &:hover,
    &:focus-within {
      --scale: scale(1.2);
    }
  }

  &:active {
    --scale: scale(0.8);
  }

  input {
    flex: none;
    position: relative;
    appearance: none;
    width: 1em;
    height: 1em;
    margin: 0;
    cursor: inherit;
    color: inherit;
    outline: none;
    border: none;
    font-size: inherit;
    font-weight: var(--font-weight, 400);
    font-family: var(--font-icon);
    transform: var(--scale);
    transition: var(--input-timing) ease-out;

    &::after {
      content: var(--glyph);
      display: inline-flex;
      pointer-events: none;
      align-items: center;
      justify-content: center;
      line-height: 0;
      width: 1em;
      height: 1em;
    }

    &:checked + span {
      --font-weight: 700;
    }
  }

  &.is-disabled {
    cursor: not-allowed;
    opacity: var(--opacity-ghost);
  }
}

.rpm-input--radio {
  --glyph: '\f111'; // circle

  input:checked {
    --glyph: '\f192'; // dot-circle
  }
}

.rpm-input--checkbox {
  --glyph: '\f0c8'; // square

  input:checked {
    --glyph: '\f14a'; // check-square // f00c checkmark
    font-weight: 900;
  }
}

.rpm-input--dropdown {
  --input-display: flex;

  select {
    appearance: none;
    font-size: inherit;
    font-family: var(--font-body);
    padding: var(--input-padding);
    padding-right: var(--space-xlarge);
    margin: 0;
    border: var(--input-border, none);
    border-left: var(--input-border-left, var(--input-border));
    border-color: var(--input-border-color, none);
    border-radius: var(--input-border-radius, var(--radius-default));
    outline: none;
    line-height: inherit;
    transition: 200ms ease-out;

    background-color: var(--input-background, var(--white));
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 11' fill='transparent' stroke-width='3' stroke='currentColor'><polyline points='1 1 10 10 19 1' /></svg>");
    background-size: 0.7em;
    background-position: calc(100% - 0.7em) 50%;
    background-repeat: no-repeat;

    &:not([size]) {
      width: 100%;
    }

    &:focus {
      border-color: transparent;
      box-shadow: 0 0 0 0.1em var(--focus-color) inset;
    }

    &[readonly] {
      --focus-color: var(--gray-lighten-4);
      cursor: default;

      color: inherit;
      background-color: var(--gray-lighten-6);
    }

    &[disabled] {
      cursor: not-allowed;
      color: var(--gray-darken-4);
      border-color: var(--gray-lighten-4);
      background-color: var(--gray-lighten-6);
    }
  }
}

.rpm-input--toggle {
  --input-grid-columns: 1fr auto;
  --input-align-items: center;
  --bg: var(--brand-gray-3);

  position: relative;

  &:not(:first-child) {
    margin: var(--input-margin-top, var(--space-default)) 0 0 0;
  }

  &.is-active {
    --bg: var(--brand-base);
    --x1: 0%;
    --x2: calc(-50% - 0.9em);
  }

  &--switch {
    display: flex;
    border-radius: 1.5em;
    overflow: hidden;
    background: var(--bg);
    box-shadow: 0 0 0 0.1em var(--bg);
    color: var(--text-light);
    position: relative;
    transition: background var(--input-timing) linear,
      color var(--input-timing) linear;

    .labels {
      margin: 0;
      flex: 0 0 200%;
      display: flex;
      line-height: 2;
      transform: translateX(var(--x1, -50%));
      will-change: transform;
      transition: transform var(--input-timing) ease-out;
      pointer-events: none;
      user-select: none;

      &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(var(--x2, calc(50% - 0.1em)), -50%) scale(1.5);
        background: currentColor;
        border-radius: 2em;
        width: 1em;
        height: 1em;
        transition: transform var(--input-timing) ease-out;
      }

      b {
        flex: 0 0 50%;
        white-space: nowrap;
        font-weight: bold;
        font-size: 0.9em;
        text-transform: uppercase;
        text-align: var(--text-align, left);
        min-width: 4ch;
        padding: var(--padding, 0 0 0 1ch);

        &.off {
          --text-align: right;
          --padding: 0 1.5ch 0 0;
        }

        &:empty::before {
          content: '\A0'; // non-breaking space :)
        }
      }
    }
  }

  input[type='checkbox'] {
    opacity: 0 !important;
    position: absolute !important;
    outline: none !important;
    margin: 0;
    padding: 0;
    width: auto;
    height: auto;
    top: 0;
    left: 0;
  }
}

.rpm-input--control--search {
  &:not(.has-icon) {
    --search-input-padding-left: var(--input-padding);
  }

  .rpm-input {
    &--element {
      padding-left: var(--search-input-padding-left, 2.5em);
      padding-right: var(--search-input-padding-right, 0);
    }

    &--search {
      &--icon {
        font-size: 0.9em;
        line-height: 0;
        pointer-events: none;
        position: absolute;
        top: 50%;
        left: var(--search-icon-left, 1em);
        transform: translate(0, -50%);
      }

      &--clear {
        --local-flex-gap: 0 0;

        line-height: 1;
        height: 100%;
        position: absolute;
        top: 50%;
        right: var(--search-icon-right, 1em);
        transform: translate(0, -50%);

        button {
          height: 100%;
          padding-left: var(--space-small);
        }
      }
    }
  }
}
