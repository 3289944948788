.avatar {
  width: var(--avatar-size, 9vmin);
  height: var(--avatar-size, 9vmin);
  font-size: 1em;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: var(--avatar-bg, var(--brand-gray-1));
  background-image: var(--bg-image);
  background-size: var(--avatar-bg-size, cover);
  background-repeat: no-repeat;
  background-position: center center;
  color: var(--avatar-fg, var(--white));
  margin-right: var(--avatar-margin-right, 0);
  white-space: nowrap;

  &--lg {--avatar-size: 15vmin;}
  &--1x {--avatar-size: 1em;}
  &--2x {--avatar-size: 2em;}
  &--3x {--avatar-size: 3em;}
  &--4x {--avatar-size: 4em;}
  &--5x {--avatar-size: 5em;}
  &--6x {--avatar-size: 6em;}
  &--7x {--avatar-size: 7em;}
  &--8x {--avatar-size: 8em;}
  &--9x {--avatar-size: 9em;}
  &--10x {--avatar-size: 10em;}

  &.has-image {
    --avatar-fg: transparent;

    box-shadow: 0 0 0 .1em var(--text-dark);
  }

  &.has-icon {
    --avatar-margin-right: var(--avatar-right-shift, .5em);
    --icon-color: var(--white);
    --icon-bg: var(--brand-black);
    --icon-size: calc(var(--avatar-size) * .4); // 1.5em;
    --icon-right: -50%;
  }

  &.is-icon {
    box-shadow: 0 0 0 2px var(--text-subtle);

    .rpm-icon--svg {
      --icon-size: var(--avatar-size);
    }
  }

  &:not(.is-icon) .rpm-icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    justify-self: flex-start;
    align-self: flex-start;
    background-color: var(--icon-bg);
    box-shadow: 0 0 0 2px var(--white);
    margin-right: -50%;
    width: var(--icon-size);
    height: var(--icon-size);
    min-width: 1.5em;
    min-height: 1.5em;

    &.flag-icon {
      justify-self: flex-end;
      align-self: flex-end;
    }

    &.badge-icon {
      justify-self: flex-end;
      align-self: flex-end;
    }
  }

  img {
    display: block;
    width: 100%;
    border: none;
    padding: 0;
    margin: 0;
    line-height: 0;
    border-radius: 50%;
  }
}
