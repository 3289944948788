.ghost {
  opacity: var(--opacity-ghost);
}

.ish {
  opacity: var(--opacity-ish);
}

.red {
  color: var(--red-base);
}
.brand-fg {
  color: var(--brand-base) !important;
}
.brand-bg {
  background-color: var(--brand-base) !important;
}

.rpm-label {
  line-height: 1;
  text-transform: uppercase;
  font-weight: bold;

  &:not(button) {
    --line-color: var(--text-dark);
  }

  .rpm-icontext--text {
    font-size: 0.9em;
    border-bottom: 2px solid var(--line-color, var(--text-dark));
  }
}

.killed {
  color: var(--red-base);

  &::before {
    content: '\f54c '; // skull
    font-family: var(--font-icon);
    font-weight: 700;
    line-height: 1;
  }

  &--box {
    background: var(--red-base);
    color: var(--white);

    margin-bottom: var(--space-medium); // ??? not long-term, works for now...

    .killed {
      color: inherit;
    }
  }
}

.line-break {
  &--dashed {
    --line-type: dashed;
  }
  &--dotted {
    --line-type: dotted;
  }

  &--before {
    padding-top: 0.5em;
    border-top: 1px var(--line-type, solid)
      var(--line-color, var(--gray-lighten-4));
    margin-top: 0.5em;
  }

  &--after {
    padding-bottom: 0.5em;
    border-bottom: 1px var(--line-type, solid)
      var(--line-color, var(--gray-lighten-4));
    margin-bottom: 0.5em;
  }
}

.divider {
  position: relative;

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    height: 1px;
    background: var(--text-light);
    width: 43%;
    opacity: var(--opacity-ish);
  }

  &::before {
    left: 0;
  }
  &::after {
    right: 0;
  }
}

.un-list {
  list-style: none;
  padding: var(--unlist-padding, 0);
  margin: var(--unlist-margin, 0);
}

.icon-list {
  list-style: none;
  padding-left: 1em;
}

@keyframes ripple {
  0% {
    transform: scale(0);
  }

  100% {
    opacity: 0;
    transform: scale(var(--scale));
  }
}

.ripple {
  --bg-color: var(--white-rgb);

  width: 0.5em;
  height: 0.5em;
  position: absolute;
  left: var(--left);
  top: var(--top);
  border-radius: 1em;
  background-color: rgba(var(--bg-color), 0.35);
  animation: ripple var(--time, 1000ms) ease-out;
  will-change: transform;
  pointer-events: none;

  &--invert {
    --bg-color: var(--black-rgb);
  }
}

.is-block {
  display: block;
}

.sticky-top {
  position: sticky;
  top: var(--sticky-top, 0);
  z-index: 10;
}

.sticky-bottom {
  position: sticky;
  bottom: var(--sticky-bottom, 0);
  z-index: 10;
}

.is-flex {
  display: flex;

  &--column {
    display: flex;
    flex-direction: column;
  }
}

.is-inline {
  display: inline;
}

.is-grid {
  display: grid;
}

.grid {
  display: var(--grid-type, grid);
  grid-template-columns: var(--grid-columns, auto);
  grid-gap: var(--grid-gap, var(--space-default));

  &--inline {
    --grid-type: inline-grid;
  }
}

.grid-column-end-2 {
  grid-column-end: span 2;
}

.w-full {
  width: 100%;
}

.flex {
  &--none {
    flex: none;
  }
  &--0 {
    flex: 0;
  }
  &--1 {
    flex: 1;
  }
  &--2 {
    flex: 2;
  }
  &--3 {
    flex: 3;
  }
  &--4 {
    flex: 4;
  }
  &--5 {
    flex: 5;
  }
  &--6 {
    flex: 6;
  }
  &--7 {
    flex: 7;
  }
  &--8 {
    flex: 8;
  }
  &--9 {
    flex: 9;
  }
}

.flex--break {
  flex-basis: 100%;
}

.flex--wrap {
  --local-flex-flow: row wrap;
}

.flex--auto-gap {
  display: flex;
  grid-gap: var(--local-flex-gap, 0 1em);
  align-items: var(--local-align-items, center);
  justify-content: var(--local-justify-content, initial);
  flex-flow: var(--local-flex-flow, row);

  &.use-baseline {
    --local-align-items: baseline;
  }
}

.flex--auto-spread {
  display: flex;
  width: 100%;
  align-items: var(--local-align-items, center);
  justify-content: space-between;
}

.flex--auto-space {
  display: flex;
  width: 100%;
  align-items: var(--local-align-items, baseline);
  justify-content: space-evenly;
}

.row-auto-gap {
  display: inline-grid;
  grid-auto-flow: column dense;
  grid-gap: 1em;
  align-items: center;
}

.fg {
  &--link {
    --color: var(--text-link);
    --icon-color: var(--color);
    color: var(--color);
  }
  &--brand {
    --button-fg: var(--brand-base);
    --color: var(--brand-base);
    --icon-color: var(--color);
    color: var(--color);
  }
  &--danger {
    --button-fg: var(--danger);
    --color: var(--danger);
    --icon-color: var(--color);
    color: var(--color);
  }
  &--brand-gray-3 {
    --button-fg: var(--brand-gray-3);
    --color: var(--brand-gray-3);
    --icon-color: var(--brand-gray-3);
    color: var(--brand-gray-3);
  }

  &--brand-gray-6 {
    --button-fg: var(--brand-gray-6);
    --color: var(--brand-gray-6);
    --icon-color: var(--brand-gray-6);
    color: var(--brand-gray-6);
  }

  &--brand-gold {
    --button-fg: var(--brand-gold);
    --color: var(--brand-gold);
    --icon-color: var(--color);
    color: var(--color);
  }
  &--accent {
    --color: var(--accent-base);
    --icon-color: var(--color);
    color: var(--color);
  }
  &--aqua {
    --color: var(--aqua-base);
    --icon-color: var(--color);
    color: var(--color);
  }
  &--blue {
    --color: var(--blue-base);
    --icon-color: var(--color);
    color: var(--color);
  }
  &--indigo {
    --color: var(--indigo-base);
    --icon-color: var(--color);
    color: var(--color);
  }
  &--purple {
    --color: var(--purple-base);
    --icon-color: var(--color);
    color: var(--color);
  }
  &--violet {
    --color: var(--violet-base);
    --icon-color: var(--color);
    color: var(--color);
  }
  &--magenta {
    --color: var(--magenta-base);
    --icon-color: var(--color);
    color: var(--color);
  }
  &--red {
    --color: var(--red-base);
    --icon-color: var(--color);
    color: var(--color);
  }
  &--sienna {
    --color: var(--sienna-base);
    --icon-color: var(--color);
    color: var(--color);
  }
  &--orange {
    --color: var(--orange-base);
    --icon-color: var(--color);
    color: var(--color);
  }
  &--yellow {
    --color: var(--yellow-base);
    --icon-color: var(--color);
    color: var(--color);
  }
  &--green {
    --color: var(--green-base);
    --icon-color: var(--color);
    color: var(--color);
  }
  &--mint {
    --color: var(--mint-base);
    --icon-color: var(--color);
    color: var(--color);
  }
  &--gray {
    --color: var(--gray-base);
    --icon-color: var(--color);
    color: var(--color);
  }
  &--dark {
    --button-fg: var(--text-dark);
    --color: var(--text-dark);
    --icon-color: var(--color);
    color: var(--color);
  }
  &--light {
    --color: var(--text-light);
    --icon-color: var(--color);
    color: var(--color);
  }
  &--gold {
    --color: var(--gold-base);
    --icon-color: var(--color);
    color: var(--color);
  }
  &--white {
    --color: var(--white);
    --icon-color: var(--color);
    color: var(--color);
  }
}

.bg {
  &--link {
    background-color: var(--text-link);
  }

  &--black {
    background-color: var(--brand-black);
  }
  &--brand {
    background-color: var(--brand-base);
  }
  &--brand-gold {
    background-color: var(--brand-gold);
  }
  &--brand-gray-3 {
    background-color: var(--brand-gray-3);
  }
  &--brand-gray-6 {
    background-color: var(--brand-gray-6);
  }

  &--accent {
    background-color: var(--accent-base);
  }
  &--aqua {
    background-color: var(--aqua-base);
  }
  &--blue {
    background-color: var(--blue-base);
  }
  &--indigo {
    background-color: var(--indigo-base);
  }
  &--purple {
    background-color: var(--purple-base);
  }
  &--violet {
    background-color: var(--violet-base);
  }
  &--magenta {
    background-color: var(--magenta-base);
  }
  &--red {
    background-color: var(--red-base);
  }
  &--sienna {
    background-color: var(--sienna-base);
  }
  &--orange {
    background-color: var(--orange-base);
  }
  &--yellow {
    background-color: var(--yellow-base);
  }
  &--green {
    background-color: var(--green-base);
  }
  &--mint {
    background-color: var(--mint-base);
  }
  &--gray {
    background-color: var(--gray-base);
  }
  &--dark {
    background-color: var(--text-dark);
  }
  &--light {
    background-color: var(--text-light);
  }
  &--gold {
    background-color: var(--gold-base);
  }
  &--white {
    background-color: var(--white);
  }
}

.icon-rotate {
  &--15 {
    transform: rotate(15deg);
  }
  &--30 {
    transform: rotate(30deg);
  }
  &--45 {
    transform: rotate(45deg);
  }
  &--60 {
    transform: rotate(60deg);
  }
  &--75 {
    transform: rotate(75deg);
  }
}

.rotate {
  &--90 {
    transform: rotate(90deg);
  }
  &--180 {
    transform: rotate(180deg);
  }
  &--270 {
    transform: rotate(270deg);
  }
}
