@media (min-width: 768px) {
  :root {
    --base-font-size: 2.7vmin;
  }
}

@media (min-width: 1024px) {
  :root {
    --base-font-size: 18px;
  }

  .one-column-content {
    .rpm-filters {
      --max-width: 52rem;
      margin: 0 auto;
    }
  }
}

@media (orientation: portrait) {
  :root {
    --flyout-position: static;
  }

  .leaderboard .leaderboard--affiliate {
    $filterCols: 3;
    --filter-cols: #{$filterCols};
  }

  .edit-profile .profile {
    --local-padding: var(--space-default) var(--space-bump) 0 var(--space-bump);
  }
}

@media (orientation: portrait) and (max-width: 374px) {
  .journal {
    --journal-filters-padding: var(--space-default) 0;
  }
}

@media (orientation: portrait) and (max-width: 768px) {
  .app-panel--right {
    --panel-content-width: 100vw;
    --panel-columns: 1fr var(--panel-content-width);
    --panel-grid-areas: 'dismiss panel';
  }

  .training--dialog--submit-score {
    --dialog-display: flex;
    --dialog-width: 100vw;
    --dialog-height: 100%;
    --dialog-max-width: 100vw;
    --dialog-max-height: 100%;
    --dialog-radius: 0;
  }

  .affiliates--staff--filters {
    --search-grid-column: 1 / 2;
    --search-grid-row: 2;
  }

  .dashboard--promotion .swiper .swiper-slide .dashboard--promotion--subtitle {
    margin-top: 0;
  }

  .rpm-card--product {
    h4 {
      font-size: var(--text-size-nudge);
    }
    .code-section {
      line-height: 0.75em;
    }

    .rpm-card--product--copy {
      --local-button-padding: 5px;
      --local-button-font-size: 0.75em;
    }
    .rpm-card--product--open {
      --local-button-padding: 5px;
      --local-button-font-size: 0.75em;
    }

    .rpm-input--field {
      input {
        --local-input-padding: 1px;

        font-size: 0.75em;
      }
    }
  }
}

@media (orientation: portrait) and (min-width: 768px) {
  .app-menu {
    --panel-columns: 1fr 1fr;
  }

  .app-panel--right {
    --panel-content-width: 80vw;
    --panel-columns: 1fr var(--panel-content-width);
    --panel-grid-areas: 'dismiss panel';
  }
}

@media (orientation: landscape) {
  :root {
    --header-width: 60vw;
    --layout-column-1: calc(100vw - var(--header-width));
    --layout-column-2: 1fr;
    --card-max-width: 20vw;
    --calendar-width: var(--layout-column-1);
    --error-border-radius: 0 0 var(--radius-bump) var(--radius-bump);
    --score-panel-flex-align: center;
  }

  .app-panel--body {
    --header-width: 100%;
  }

  .rpm-hero.is-playing + nav {
    --filters-top: 0;
  }

  .rpm-filters {
    .rpm-input--control--search {
      padding: unset;
    }

    .filter-column-spacer {
      display: none;
    }
  }

  .app-menu {
    --panel-columns: 40vw 1fr;
    --panel-grid-areas: 'panel dismiss';
  }

  .app-panel--right {
    --panel-content-width: 60vw;
    --panel-columns: 1fr var(--panel-content-width);
    --panel-grid-areas: 'dismiss panel';
  }

  .app-footer br {
    display: none;
  }

  .login {
    background-position: center center;
  }

  .dashboard {
    --aheader-height: calc(100vh - 64px);
    --aheader-width: calc(var(--aheader-height) * 0.8);

    --layout-column-1: calc(100vw - var(--aheader-width));
    --layout-column-2: 1fr;

    .app-main {
      --local-bg: var(--brand-black);
      --layout-rows: auto 1fr;
      --layout-areas: 'user promo' 'content promo';

      .dashboard--promotion {
        height: var(--aheader-height);
        width: var(--aheader-width);

        &.is-not-playing {
          padding: 0;
          position: sticky;
          top: 0;
          z-index: 20;
        }
        &.is-playing {
          position: sticky;
        }

        &--button {
          font-size: 2vmin;
        }

        .swiper {
          height: var(--aheader-height);
          width: var(--aheader-width);
          .swiper-slide {
            .dashboard--promotion--image {
              height: 100vh;
              width: var(--aheader-width);

              .promo-image {
                height: var(--aheader-height);
                width: var(--aheader-width);
              }
            }
          }
        }

        .brightcove-react-player-loader {
          height: var(--aheader-height);
          width: var(--aheader-width);
        }
      }

      .nutrition--item {
        --header-width: var(--layout-column-1);
        --header-height: calc(var(--header-width) * var(--header-ratio));
      }
    }
  }

  .workshop--item {
    .rpm-hero {
      font-size: 2vmin;
    }
  }

  .training .app-main {
    --local-bg: var(--brand-black);
    --layout-rows: auto auto auto 1fr auto auto auto auto;
    --layout-areas: 'profile header' 'filters header' 'workouts header'
      'workouts header' 'workouts segments' 'workouts segments'
      'athletes segments' 'products products';

    .is-not-playing {
      padding: 0;
      position: sticky;
      top: 0;
      z-index: 20;
    }

    .rpm-tooltip {
      --tooltip-top: calc(
        var(--tooltip-y) - var(--height-of-filters, 0) - 3.5em
      );
    }

    .training {
      &--featured-athletes {
        border-top: none;
      }

      &--equipment {
        --local-border-width: 0;
        background: linear-gradient(
          var(--brand-gray-7) 0,
          var(--white) var(--space-max)
        );
      }

      &--video-segments {
        --icon-color: var(--text-light);
        background: var(--brand-black);
        color: var(--icon-color);

        li + li {
          border-top: 1px solid var(--brand-gray-0);
        }
      }
    }
  }

  .training {
    .training--log-buttons {
      &--one-column {
        display: grid;
      }

      &--full-width {
        display: none;
      }
    }

    &--dialog--demo-video {
      --dialog-width: 50vw;
    }
  }

  .affiliates .app-main {
    $filterCols: 2;
    --filter-cols: #{$filterCols};

    .affiliates--overview {
      --media-grid-padding: var(--block-padding);
      --media-grid-gap: 1em;
    }
  }

  .leaderboard-10k .app-main {
    $filterCols: 3;
    --filter-cols: #{$filterCols};
    --search-grid-column: 3;
    --search-grid-row: 1;
  }

  .journal .app-main,
  .leaderboard .app-main {
    $filterCols: 4;
    --filter-cols: #{$filterCols};
  }

  .leaderboard .leaderboard--affiliate {
    $filterCols: 5;
    --filter-cols: #{$filterCols};
  }

  .edit-profile .app-main {
    --layout-column-1: 1fr;
    --layout-column-2: 1fr;
    --layout-rows: auto auto 1fr;
    --local-padding: var(--space-xxlarge);

    .profile {
      &--image {
        --local-margin: 0;
        --local-margin-top: 0;

        display: flex;
        align-items: center;
        justify-content: center;
        gap: var(--space-bump);
      }
    }
  }

  .event10k {
    .app-main {
      --local-bg: var(--brand-gray-7);
      --header-width: 50vw;
      --layout-rows: auto auto auto auto 1fr auto auto;
      --layout-column-1: 50vw;
      --layout-areas: 'filters header' 'my-stats header' 'my-stats team-members'
        'my-stats team-members' 'team-stats team-members'
        'team-stats team-members';
    }

    &--dialog--finisher {
      --dialog-width: 50vw;
      --dialog-local-display: flex;
      --dialog-local-padding-top: 0;
    }
  }

  .one-column-content {
    --max-width: 32rem;
    --filter-gap: 1px 0;

    &.is-full-width {
      --max-width: 100%;
      --app-content-margin: 0;
    }

    footer,
    .app-content--inner {
      margin: var(--app-content-margin, 0 auto);
      width: var(--max-width);
      max-width: 100%;
    }

    .rpm-filters,
    .rpm-subheader {
      --padding-x: calc((100% - var(--max-width)) / 2);

      padding-left: calc(var(--padding-x) + var(--space-bump));
      padding-right: calc(var(--padding-x) + var(--space-bump));
    }
  }

  .app-main:not(.one-column-content) {
    display: grid;
    grid-template-columns: var(--layout-column-1) var(--layout-column-2);
    grid-template-rows: var(--layout-rows, auto);
    grid-template-areas: var(--layout-areas, auto);
  }

  .journal--filters--advanced {
    .rpm-calendar--body {
      top: unset;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}

@media (orientation: portrait) and (min-width: 694px) {
  :root {
    --media-items: 2;
  }
}

@media (orientation: landscape) and (min-width: 768px) {
  :root {
    --media-items: 3;
  }

  .app-panel--right {
    --panel-content-width: 50vw;
  }
}

@media (orientation: landscape) and (min-width: 1024px) {
  :root {
    --media-items: 3;
  }

  .app-panel--right {
    --panel-content-width: 40vw;
  }
}

@media (orientation: landscape) and (min-width: 1180px) {
  :root {
    --media-items: 4;
  }

  .app-panel--right {
    --panel-content-width: 33vw;
  }
}

@media (orientation: landscape) and (min-width: 1920px) {
  :root {
    --media-items: 5;
  }

  .app-panel--right {
    --panel-content-width: 25vw;
  }
}

@media (orientation: landscape) and (min-width: 2500px) {
  :root {
    --media-items: 6;
  }
}

@media (orientation: landscape) and (max-width: 768px) {
  :root {
    --media-items: 2;
  }

  .app-panel--right {
    --panel-content-width: 80vw;
  }

  .one-column-content {
    --max-width: 100%;
  }
}

@media (orientation: landscape) and (max-width: 812px) {
  :root {
    --header-width: 50vw;
  }

  .login {
    #root {
      grid-template-rows: 1fr auto;
      grid-template-areas:
        'main'
        'footer';
    }

    .app-main {
      width: auto;

      .login--content {
        --button-group-padding: var(--space-bump) 0 0 0;

        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: center;
        grid-gap: var(--space-max);
        width: auto;

        &--centered {
          display: flex;
          justify-content: center;
        }
      }
    }

    .app-footer {
      padding-top: 0;
    }
  }
}

// @NOTE for some reason, the footer text is huge on actual devices in landscape - Jase
@supports (-webkit-touch-callout: none) {
  @media (orientation: landscape) {
    .app-footer {
      font-size: var(--text-size-small);
    }
  }
}
