.rpm-line-clamp {
  &.show-more {
    --clamp: none;
  }

  overflow: hidden;

  // display: box; // spec, old, replaced by 'flex' BUT line clamp doesn't work with that - Jase
  display: -moz-box; // Gecko
  display: -webkit-box; // WebKit

  box-orient: vertical; // spec
  -moz-box-orient: vertical; // Mozilla
  -webkit-box-orient: vertical; // WebKit

  line-clamp: var(--clamp, var(--lines, 2));
  -moz-line-clamp: var(--clamp, var(--lines, 2));
  -webkit-line-clamp: var(--clamp, var(--lines, 2));
}
