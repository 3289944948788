.event10k {
  --header-padding: 0;
  --header-height: calc(var(--header-width) * var(--header-ratio));
  --user-row-bg: var(--brand-gray-7);
  --user-row-border-bottom: 0;
  --local-border-width: 0;
  --input-margin-top: 0;

  &--dialog {
    &--finisher {
      --dialog-content-padding: 0;
      --dialog-width: 90vw;
      --local-justify-content: center;

      .rpm-dialog--section--buttons {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        padding: var(--block-padding);
        border: none;
        text-align: center;
        display: var(--dialog-local-display, block);
        align-items: center;
        justify-content: space-between;

        > * + * {
          padding-top: var(--dialog-local-padding-top, var(--space-small));
        }
      }

      .rpm-dialog--dismiss {
        margin: var(--space-min);
      }
    }
  }

  .my-stats {
    grid-area: my-stats;
  }

  .team-stats {
    grid-area: team-stats;
  }

  .team-members {
    grid-area: team-members;
  }

  section {
    .rpm-description+.rpm-description {
      --local-padding: 0 var(--space-bump) var(--space-default) var(--space-bump);
    }

    +section {
      border-top: 1px solid var(--block-border-color, var(--brand-gray-6));
    }
  }

  .days-to-jump {
    &--breaker-bar {
      color: var(--text-light);
      background: var(--text-dark);
      text-align: center;
      padding: var(--space-xsmall) var(--space-bump);
      text-transform: uppercase;
      font-size: small;
    }

    img {
      max-height: 50px;
      display: block;
    }
  }

  .user--row,
  h5 {
    --button-text-decoration: none;
    --button-font-size: 1em;
  }

  .avatar-10k {
    --avatar-bg-size: 75%;
  }

  .chart {
    &--progress {
      --x-shift: calc(var(--shift, 1) * 100%);
      --step: calc(var(--pct) - var(--over));

      position: relative;
      text-transform: uppercase;
      font-size: var(--text-size-small);
      color: var(--text-ish);

      &.has-overage {
        --goal-edge: 1px dotted var(--brand-gray-4);
      }

      &--bar {
        background: var(--brand-gray-6);
        padding: var(--space-bump) 0;
        border-radius: var(--radius-default);
        overflow: hidden;
        position: relative;
        color: var(--color);
      }

      &--value {
        background: var(--brand-gray-2);
        background-image: linear-gradient(90deg,
          var(--brand-gray-2) 0,
          var(--brand-gray-1) var(--step));
        position: absolute;
        width: var(--pct);
        top: 0;
        left: 0;
        bottom: 0;

        box-shadow: 0 0 0 .1rem var(--white);

        >* {
          position: absolute;
          top: 50%;
          right: 0; // var(--over);
          transform: translate(var(--x-shift, 0%), -50%);
          padding: 0 var(--space-small);
          opacity: .65;
        }
      }

      &--goal {
        display: block;
        text-align: right;
        padding: var(--space-min);
        max-width: calc(100% - var(--over));
        border-right: var(--goal-edge, none);
      }

      &--label {
        display: block;
        padding: var(--space-min) 0;
      }
    }

    &--bubble {
      position: relative;
      text-transform: uppercase;
      font-size: var(--text-size-small);
      color: var(--text-ish);

      &--row {
        padding: var(--space-bump) 0;
        display: flex;
        align-items: baseline;
        grid-gap: 0;
        background: repeating-linear-gradient(var(--brand-gray-6) 0,
            var(--brand-gray-6) 1px,
            transparent 1px,
            transparent 2em);
      }

      &--segment {
        --height: calc(var(--pct) * .05rem);

        flex: 1;
        position: relative;
        padding: 0 var(--space-default);
        border-radius: var(--radius-default);
        width: var(--space-large);
        height: var(--height);
        opacity: var(--opacity);

        .bubble-value {
          position: absolute;
          bottom: 100%;
          left: 50%;
          transform: translate(-50%, 0);
        }

        .bubble-label {
          position: absolute;
          top: 100%;
          left: 0;
          right: 0;
          border-top: 1px solid var(--brand-gray-3);
          text-align: center;
          padding: var(--space-min) 0;
        }

        &::after {
          --width: calc(var(--pct) * .02em);
          --height: var(--width);

          content: '';
          position: absolute;
          padding: 0;
          background: var(--brand-gray-2);
          border-radius: 50%;
          top: 0;
          left: 50%;
          width: var(--width);
          height: var(--height);
          transform: translate(-50%, 0%);
        }
      }

      &--label {
        display: block;
        position: sticky;
        left: 0;
        padding: var(--space-min) 0;
      }
    }

    &--stacked {
      position: relative;
      text-transform: uppercase;
      font-size: var(--text-size-small);
      color: var(--text-ish);
      overflow-x: auto;

      &--row {
        padding: var(--space-bump) 0;
        display: flex;
        align-items: baseline;
        grid-gap: var(--space-xsmall);
      }

      &--bar {
        --height: calc(var(--pct) * .05rem);

        flex: 1;
        position: relative;
        background: var(--brand-gray-1);
        padding: 0 var(--space-default);
        border-radius: var(--radius-default);
        width: var(--space-large);
        height: var(--height);
        opacity: var(--opacity);

        >* {
          position: absolute;
          bottom: 100%;
          left: 50%;
          transform: translate(-50%, 0);
        }

        >small {
          bottom: auto;
          top: calc(100% + var(--space-xsmall));
        }
      }

      &--label {
        display: block;
        position: sticky;
        left: 0;
        padding: var(--space-min) 0;
      }
    }
  }

  .flip-character {
    position: relative;
    display: inline-block;
    background: var(--text-dark);
    color: var(--text-light);
    padding: 0 var(--space-min);
    margin: -.3em 0 -.3em .2em;
    font-size: 1.25rem;
    line-height: 1.65;
    border-radius: .1em;

    &::after {
      content: '';
      height: 1px;
      background: var(--text-light);
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      transform: translateY(-50%);
    }
  }

  .stats {
    &--row {
      --local-margin-top: 0;

      text-align: center;
      padding: var(--space-default) var(--space-bump) 0 var(--space-bump);
    }
  }
}
