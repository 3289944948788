:root {
  --kit-icon-width-power: 0.66em;
  --kit-icon-width-axis: 1em;
  --kit-icon-width-fly: 1.08em;
  --kit-icon-scaler: 0.8;
}

.rpm-icon {
  color: var(--icon-color, currentColor);
  transition: color var(--base-transition-timing) linear;

  &--svg {
    width: var(--svg-width, var(--icon-size, 1em));
    height: var(--svg-height, var(--icon-size, 1em));
    stroke-linejoin: round;
    stroke-linecap: round;
    stroke-width: 0;
    stroke: var(--icon-color, currentColor);
    fill: var(--icon-color, currentColor);
    vertical-align: middle;
  }

  &--kit {
    --icon-size: 0.8em;
  }

  &--power-kit {
    --svg-width: calc(var(--kit-icon-width-power) * var(--kit-icon-scaler));
  }

  &--fly-kit {
    --svg-width: calc(var(--kit-icon-width-fly) * var(--kit-icon-scaler));
  }

  &--video-play-outline {
    --icon-circle-fill: transparent;
    --icon-triangle-fill: transparent;
    --icon-circle-stroke: var(--icon-color, currentColor);
    --icon-triangle-stroke: var(--icon-color, currentColor);

    stroke-width: 2;
  }

  &--with-badge {
    position: relative;
  }

  &--badge {
    --local-font-size: 0.75em;
    --local-font-weight: bold;
    --local-line-height: 0;

    position: absolute;
    top: 0;
    right: 0;
    background: var(--badge-bg, var(--brand-base));
    color: var(--badge-fg, var(--white));
    box-shadow: 0 0 0 0.2em var(--badge-edge, var(--white));
    border-radius: 1em;
    min-width: 1.75em;
    height: 1.75em;
    padding: 0 var(--space-min);
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translate(25%, -25%);
  }

  &--play {
    font-size: var(--text-size-bump);
  }
}

.rpm-icontext {
  &:not(.has-icon) {
    display: inline-block;
  }

  &.has-icon.has-text {
    display: inline-grid;
    grid-auto-flow: column dense;
    align-items: var(--align-items, center);
    grid-gap: var(--gap, 0.5ch);
    vertical-align: middle;

    &.is-flipped {
      --gap: 1ch;
    }
  }

  * + * {
    margin: 0;
  }

  &--set {
    > * + * {
      margin-left: var(--gap, 1ch);

      &::before {
        content: '/';
      }
    }
  }
}

.rpm-logo {
  --x-scale: var(--local-x-scale, var(--logo-x-scale));
  --y-ratio: var(--local-y-ratio, var(--logo-y-ratio));
  --x: calc(var(--local-x, var(--logo-x)) * var(--x-scale));
  --y: calc(var(--x) * var(--y-ratio));
  --color: var(--black);

  width: var(--x);
  height: var(--y);
  fill: var(--color);

  &--white {
    --color: var(--white);
  }

  &--atom {
    --logo-x: 21rem;
    --logo-x-scale: 1;
    --logo-y-ratio: 0.1127;
  }
}

.rpm-marker {
  justify-self: center;

  width: var(--row-icon-size, var(--row-icon-width, 3em));
  height: var(--row-icon-size, var(--row-icon-height, 3em));
  font-size: var(--row-font-size, 0.9em);
  font-weight: bold;
  line-height: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: var(--radius-default);
  background-color: var(--row-icon-bg, var(--brand-gray-6));
  color: var(--row-icon-fg, var(--text-dark));
  overflow: hidden;
  position: relative;
  text-transform: uppercase;

  &--brand {
    --row-icon-bg: var(--brand-base);
    --row-icon-fg: var(--text-light);
    --row-future-icon-bg: var(--brand-lighten-2);
    --row-future-icon-ear: var(--brand-base);
    --row-future-icon-shadow: var(--brand-darken-1);
  }

  &--event {
    --row-icon-bg: var(--brand-black);
    --row-icon-fg: var(--orange-lighten-2);
    --row-future-icon-bg: var(--brand-gray-0);
    --row-future-icon-ear: var(--orange-darken-3);
    --row-future-icon-shadow: var(--brand-black);
  }

  &--inactive {
    --row-icon-bg: var(--brand-gray-5);
    --row-icon-fg: var(--brand-gray-1);
    --row-future-icon-bg: var(--brand-gray-5);
    --row-future-icon-ear: var(--brand-gray-3);
    --row-future-icon-shadow: var(--brand-black);
  }

  &--day-block {
    --row-icon-bg: transparent;
  }

  &--neutral {
    --row-icon-bg: transparent;
    --row-icon-size: auto;
    --row-font-size: 1em;
  }

  small {
    font-size: 0.7em;
  }

  span {
    font-size: 1.1em;
  }

  &.has-image {
    background-size: 60%;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-image: var(--row-icon);
  }

  &.is-future {
    --row-icon-bg: var(--row-future-icon-bg);

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      padding: 0.38em;
      border-radius: 0 0 var(--radius-default) 0;
      background: var(--row-bg)
        linear-gradient(
          135deg,
          var(--row-bg) 50%,
          var(--row-future-icon-ear) 50%
        );
      box-shadow: -1px 2px 2px 0 var(--row-future-icon-shadow);
    }
  }
}

.avatar .flag-icon {
  display: block;
  background-size: cover;
  width: var(--icon-size, 100%);
  height: var(--icon-size, 100%);
}
