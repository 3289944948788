.app-public-profile {
  overflow-y: auto;
  --user-avatar-scale: 1;

  &--inner {
    --local-flex-flow: row wrap;

    background: linear-gradient(to bottom, var(--brand-gray-7), var(--white));
  }

  &--row {
    padding: var(--block-padding);
    border-top: 1px solid var(--brand-gray-6);

    &.is-prime {
      border-top: none;
    }
  }

  &--stats {
    &--title {
      text-transform: capitalize;
      display: grid;
      grid-template-columns: 1fr auto;
      grid-gap: var(--space-default);
      align-items: center;

      small {
        font-weight: normal;
      }
    }

    span {
      + span::before {
        content: '/';
        display: inline-block;
        position: relative;
        padding: 0 var(--space-small);
        opacity: .4;
      }
    }
  }
}
