.resources {
  .one-column-content {
    --max-width: 48rem;
  }

  &--content {
    grid-area: content;
  }

  &--resources {
    ul {
      list-style: none;
      margin: 0;
      padding: var(--space-default) 0 0 0;

      li + li {
        padding-top: var(--space-default);
      }
    }
  }

  .expander {
    --button-text-decoration: none;
    --button-display: flex;

    gap: 1ch;
  }

  details {
    border-radius: var(--radius-default);
    transition: background var(--base-transition-timing) linear;
    margin: 0 var(--negative-space-min);

    &[open] {
      --rotate: rotate(90deg);

      background: var(--brand-gray-7);
    }
  }

  summary {
    cursor: pointer;
    outline: none;
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    gap: var(--space-bump);
    padding: var(--space-min);

    // gecko/mozilla
    list-style: none;

    // blink/webkit
    &::-webkit-details-marker {
      display: none;
    }

    .marker {
      --icon-color: rgba(var(--black-rgb), 0.3);

      transform: var(--rotate);
      transition: transform 200ms ease-in-out;
    }
  }

  .resource {
    &--details {
      padding: var(--space-min);
    }

    &--row {
      --icon-color: var(--text-link);
      --icon-element-outline: var(--icon-color);
      --button-display: flex;
      --svg-width: 1.25em;

      display: var(--button-display);
      align-items: center;
      justify-content: space-between;
      width: 100%;
      text-decoration: none;

      &--icon {
        font-size: 1.2em;
      }
      &--label {
        padding-right: 1em;
      }
    }
  }
}
