.rpm-description {
  background: var(--block-bg, var(--brand-gray-7));
  border-bottom: var(--local-border-width, 1px) solid var(--brand-gray-6);
  padding: var(--local-padding, var(--block-padding));
  transition: background var(--base-transition-timing) linear;

  &--fallback {
    text-align: center;
  }

  &.ui-dark {
    color: var(--text-light);
    background: var(--text-dark);
  }

  &.sticky-bottom {
    border-top: var(--local-border-width, 1px) solid var(--brand-gray-6);
  }
}

.rpm-header {
  --input-margin-top: 0;

  white-space: nowrap;
  background: var(--header-block-bg, var(--brand-gray-7));
  border-top: var(--local-border-width, 1px) solid var(--brand-gray-6);
  border-bottom: var(--local-border-width, 1px) solid var(--brand-gray-6);
  padding: var(--local-padding, var(--block-padding));
  transition: background var(--base-transition-timing) linear;

  &.is-thin {
    --local-padding: var(--space-xsmall) var(--space-bump);
  }
}

.rpm-marquee {
  --bg: var(--block-bg, var(--brand-gray-7));
  --user-row-bg: var(--bg);
  --fg: var(--block-fg, var(--local-fg, inherit));
  --user-row-bg: var(--fg);

  background: var(--bg);
  color: var(--fg);
  transition: background var(--base-transition-timing) linear;
}

.rpm-block {
  --button-width: auto;
  padding: var(--local-padding, var(--block-padding));

  + .rpm-block,
  + .rpm-button-block {
    --local-padding: var(--block-padding-topless);
  }
}

.rpm-button-block {
  display: grid;
  grid-template-columns: var(--local-template-columns, 1fr 1fr);
  grid-gap: 1em;
  padding: var(--local-padding, var(--block-padding));

  &.is-shaded {
    background: var(--block-bg, var(--brand-gray-7));
    border-bottom: var(--local-border-width, 1px) solid var(--brand-gray-6);
  }
}

.rpm-data-list {
  padding: 0;
  margin: 0;
  list-style: none;
}

.rpm-hero {
  --local-margin-top: 0;
  --header-height: calc(var(--header-width) * var(--header-ratio));

  position: relative;
  font-size: 5vmin;
  line-height: var(--hero-line-height, 1);
  padding: var(--header-padding, var(--block-padding));
  width: var(--header-width);
  height: var(--header-height);
  max-width: 100%;
  color: var(--white);
  background-color: var(--brand-black);
  background-image: linear-gradient(100deg, rgba(#000, 1), rgba(#000, 0) 65%),
    var(--header-bg-image);
  background-size: cover;
  background-position: 50% 40%;

  &.is-playing {
    padding: 0;
    position: sticky;
    top: 0;
    z-index: 20;

    + nav {
      --filters-top: var(--header-height);
    }
  }

  &--info {
    width: 75%;
  }

  &--cta {
    line-height: 0;
    font-size: initial;

    .rpm-button {
      line-height: var(--root-line-height);
      width: auto;
    }

    .rpm-icon--video-play {
      --icon-circle-stroke: var(--brand-base);
      stroke-width: 3;
    }
  }
}

.rpm-filters {
  $filterCols: 2;
  --local-filter-cols: var(--filter-cols, #{$filterCols});
  --local-top: 3em;
  --position: static;
  --input-margin-top: 0;

  display: grid;
  grid-template-columns: repeat(var(--local-filter-cols), 1fr);
  align-items: center;
  justify-content: space-around;
  grid-gap: var(--filter-gap, 1px);
  position: sticky;
  top: var(--filters-top, -1px);
  color: var(--text-dark);
  background: var(--white);
  border-bottom: 1px solid var(--brand-gray-6);
  z-index: var(--filters-z-index, 10);
  font-size: 0.9em;

  > *:nth-child(n + #{$filterCols + 1}) {
    --local-top: calc(var(--local-top) * 2);
  }

  .rpm-input--control--search {
    grid-column: var(--search-grid-column, 1 / 3);
    grid-row: var(--search-grid-row, 2);
    background: var(--white);

    .rpm-input--help-message {
      position: absolute;
      margin: 0 0.5em;
      border-radius: var(--radius-default);
      top: calc(100% - 0.5em);
      background: var(--brand-gray-7);
      max-width: 100%;
      box-shadow: 0 0 0 1px var(--brand-gray-5);
      padding: var(--space-small);
    }
  }
}

.rpm-subheader {
  background: var(--subheader-bg, var(--brand-gray-7));
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding: var(--space-min) var(--space-bump);
  font-size: var(--text-size-small);
  border-top: 1px solid var(--white);
  border-bottom: 1px solid var(--brand-gray-6);
  box-shadow: 0 -1px 0 0 var(--brand-gray-6);
  position: sticky;
  top: var(--height-of-filters, 0);
  z-index: var(--subheader-z-index, 9);
}

.rpm-container {
  flex: 1;

  color: var(--local-fg, var(--text-dark));
  background: var(--local-bg, var(--white));
  position: relative;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  &.ui-dark {
    --local-fg: var(--text-light);
    --local-bg: none;
  }

  &--button-group {
    text-align: center;
    padding: var(--button-group-padding, var(--space-xxlarge));
  }

  > footer {
    background: linear-gradient(to bottom, var(--brand-gray-7), var(--white));
    color: var(--text-dark);
    border-top: 1px solid var(--brand-gray-6);
    padding: var(--space-default);
    display: flex;
    align-items: center;
    justify-content: center;
    position: sticky;
    bottom: 0;

    button {
      flex: var(--local-flex, 1);
      box-shadow: none;
      padding: var(--local-padding, var(--space-small));
      letter-spacing: 0.1ch;
      font-weight: 700;
      white-space: nowrap;

      + button {
        margin-left: var(--local-margin-left, var(--space-default));
      }
    }
  }

  h1,
  h2,
  h3,
  h4 {
    font-family: var(--font-heading);
    font-weight: var(--font-weight, 900);
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  p {
    margin: var(--local-margin, 0);
  }

  *:not(:empty) {
    + p:not(:empty),
    + ul:not(:empty),
    + ol:not(:empty),
    + h2:not(:empty),
    + h3:not(:empty),
    + h4:not(:empty) {
      margin-top: var(--local-margin-top, var(--space-default));
    }
  }

  sup {
    text-transform: uppercase;
    font-size: var(--local-font-size, 0.7em);
    font-weight: var(--local-font-weight, normal);
    margin-left: var(--local-sup-margin-left, 0.3ch);
    line-height: var(--local-line-height, 1);
  }
}

.rpm-featured-products {
  overflow: hidden;
  background: var(--white);
  border-top: 1px solid var(--brand-gray-6);
  padding: var(--block-padding);
  padding-bottom: 0;
}

.rpm-training-score {
  --local-margin: var(--space-min) 0 0 0;
  --local-margin-top: var(--space-min);

  text-align: center;
  text-transform: uppercase;
  font-weight: 500;
  font-size: var(--local-font-size, var(--text-size-bump));
  line-height: 1;
}

.rpm-hero .rpm-training-score {
  padding: var(--block-padding);
  padding-top: 0;
  margin-right: 0;
  margin-left: auto;
}

.rpm-description .rpm-training-score {
  > p:last-child {
    opacity: var(--opacity-ghost);
  }
}

.rpm-workout {
  --local-margin-top: var(--space-min);

  flex: 1;

  h2,
  h3 {
    text-transform: uppercase;
  }

  h2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  h3 {
    --local-margin-top: var(--space-default);
  }

  > ul,
  > ul ul {
    list-style: none;
    padding: 0;
    margin: 0 0 var(--space-default) 0;
  }
}

.rpm-md {
  * {
    margin: 0;

    + * {
      --local-margin-top: var(--space-min);
    }
  }

  p {
    + * {
      --local-margin-top: var(--space-default);
    }
  }

  ul,
  ol {
    padding: 0 0 0 var(--space-large);

    + * {
      --local-margin-top: var(--space-default);
    }
  }
}

.rpm-user--hero {
  --dialog-content-padding: 0;
  background: var(--text-dark);
  color: var(--text-light);
  overflow: hidden;
  position: relative;

  &.has-image {
    --hero-details-position: absolute;
    height: 15em;
  }

  &--details {
    --local-margin-top: 0;

    position: var(--hero-details-position, relative);
    bottom: 0;
    left: 0;
    right: 0;
    padding: var(--block-padding);
    background: linear-gradient(
      to bottom,
      rgba(var(--black-rgb), 0),
      rgba(var(--black-rgb), 0.9)
    );
  }

  &--image {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: 50% 30%;
  }
}
