.user--row {
  --local-margin-top: 0;
  --button-fg: var(--text-dark);
  --button-font-size: 1em;

  grid-area: user;

  display: grid;
  grid-template-columns: var(--user-row-grid-columns, auto 1fr);
  grid-gap: var(--space-small);
  align-items: center;
  padding: var(--block-padding);
  border-bottom: var(--user-row-border-bottom, 1px solid var(--brand-gray-6));
  color: var(--button-fg);
  background: var(--user-row-bg, var(--text-light));

  &.has-action {
    --user-row-grid-columns: auto 1fr auto;
    --user-row-avatar-shift: var(--negative-space-min) 0;
  }

  &.ui-dark {
    --button-fg: var(--text-light);
    --user-row-bg: var(--text-dark);
  }

  &--avatar {
    justify-self: center;
    transform: scale(var(--user-avatar-scale, 1));
    margin: var(--user-row-avatar-shift, 0);
  }

  &--username {
    font-weight: var(--button-font-weight);
  }

  &--action {
    align-self: var(--user-row-action-align, baseline);
    display: flex;
    align-items: center;
    gap: var(--space-nudge);
  }

  &--meta {
    --local-flex-gap: var(--space-min);

    .workout-stats-profile {
      --local-flex-gap: var(--space-normal);

      &--item {
        --local-flex-gap: var(--space-min);
      }
    }
  }

  p {
    margin: 0;
  }
}

.user--meta {
  &--kcl {
    --button-font-weight: normal;
    --button-font-size: 1em;
    --gap: 0.3ch;
    --icon-color: var(--brand-gray-4);

    .is-liked {
      --icon-color: var(--brand-base);
    }
  }

  &--kit {
    --icon-color: var(--text-dark);
    --gap: 0.7ch;
    line-height: 1;

    &--light {
      --icon-color: var(--white);
      --icon-fg: var(--text-dark);
    }
  }
}

.is-smartified .user--meta--kit {
  margin-left: 1ch;
  font-weight: normal;
}

.underline-lower {
  position: relative;
}
.underline-lower::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 2px; /* Adjust as needed */
  width: 100%;
  height: 2px; /* Underline thickness */
  background-color: black; /* Underline color */
}
