.assessment-inputs {
  --local-margin-top: 0;
  --input-border: 0.07em solid var(--brand-gray-3);
  --group-background: transparent;

  background-color: var(--brand-gray-0);

  &--cluster {
    padding: var(--space-large) var(--space-xxlarge) var(--space-large)
      var(--space-xxlarge);
    border-bottom: 1px solid rgba(var(--white-rgb), 0.35);
    .rpm-input--group--items {
      --group-background: transparent;
    }
  }

  &--options {
    margin-bottom: var(--space-small);
  }

  .rpm-input {
    &--group--items.rpm-input--group--items {
      --group-display: grid;
      --columns: auto auto;
      color: var(--white);

      .rpm-input:not(:first-child) {
        border: 0;
      }
    }

    &--radio {
      padding-right: var(--space-small);
    }
  }
}

.has-background {
  background-color: var(--brand-gray-0);
}

.assessment {
  &--container {
    transform: translateY(-100%);

    &.active {
      transition: transform 0.4s ease-out;
      transform: translateY(0%);
    }
  }

  &--header {
    --tooltip-icon-opacity: 1;
    background: var(--brand-gray-6);
    padding: var(--block-padding);
    color: var(--text-dark);

    &--tooltip {
      margin-top: var(--space-default);
    }
  }

  &--check-list {
    text-align: left;
    padding: 0;
    margin-bottom: 0;
    list-style: none;

    li {
      border-top: 1px solid rgba(var(--white-rgb), 0.35);
      user-select: none;
      display: grid;
      grid-template-columns: 1fr auto;
      grid-gap: var(--space-bump);
      align-items: center;
      padding: var(--space-default) 0;
      text-transform: uppercase;
    }

    li:last-child {
      padding-bottom: 0;
    }
  }

  &--list {
    --local-margin-top: 0;
    text-align: left;
    padding: 0;
    margin: 0;
    list-style: none;
    background: var(--brand-gray-0);
    border-bottom: 1px solid rgba(var(--white-rgb), 0.2);
    font-size: 0.95em;

    &--item {
      position: relative;
      border-top: 1px solid rgba(var(--white-rgb), 0.2);
      user-select: none;

      &.is-selected {
        background: var(--brand-black);
      }

      &.is-selectable {
        --cols: auto 1fr;
        cursor: pointer;
      }

      &.has-tooltip {
        --cols: 1fr auto;

        &.is-selectable {
          --cols: auto 1fr auto;
        }

        &.is-open {
          --tooltip-padding-top: var(--space-min);
          --tooltip-icon-color: var(--brand-lighten-1);
          --tooltip-icon-opacity: 1;
          --tooltip-line-height: var(--root-line-height);
          --tooltip-opacity: 0.6;
          --tooltip-delay: 100ms;
          --tooltip-content-space: var(--space-xsmall);
        }
      }
    }

    img {
      vertical-align: middle;
      width: 100%;
      height: auto;
    }
  }

  &--option {
    display: grid;
    grid-template-columns: var(--cols, 1fr auto);
    grid-gap: var(--space-bump);
    align-items: baseline;
    outline: none;
    padding: var(--block-padding);
    position: relative;
    overflow: hidden;

    > * {
      pointer-events: none;
    }

    button {
      color: inherit;
      pointer-events: all;
    }
  }

  &--text {
    --local-margin-top: var(--space-small);
  }

  &--tooltip {
    --local-margin-top: var(--tooltip-content-space, 0);

    color: var(--tooltip-color, var(--white));
    line-height: var(--tooltip-line-height, 0);
    transition: var(--base-transition-timing) ease-in-out,
      opacity 200ms ease-in-out var(--tooltip-delay, 0ms);
    font-size: 0.9em;
    padding-top: var(--tooltip-padding-top, 0);
    opacity: var(--tooltip-opacity, 0);

    &--cue {
      --icon-color: var(--tooltip-icon-color, inherit);
      --button-radius: var(--radius-full-circle);

      position: absolute;
      right: 0;
      top: 0.5rem;
      width: var(--space-xlarge);
      height: var(--space-xlarge);

      .rpm-icon {
        opacity: var(--tooltip-icon-opacity, 0.4);
        pointer-events: none;
      }
    }

    ul {
      list-style-type: disc;
      padding-left: var(--space-large);
      margin-top: var(--tooltip-content-space, 0);
    }
  }
}

.assessment-done {
  --button-group-padding: var(--space-xlarge) 0;

  font-size: var(--text-size-default);
  padding: var(--space-xxlarge);
}

.kit-recommendation {
  --local-margin: var(--space-bump) 0 0 0;
  --button-group-padding: 0 var(--space-xxlarge);
  text-align: center;
}

.assessment--text--primary ul {
  margin-top: var(--space-small);
  list-style: none;
  padding-left: 0px;
}
