.workshops {
  .app-content--inner {
    --max-width: var(--header-width);
  }

  background: var(--brand-gray-7);
}

.workshop--item {
  --item-width: calc(var(--header-width) - 2 * var(--space-bump));
  --item-height: calc(var(--item-width) * var(--video-height-ratio));
  &--image {
    background-image: linear-gradient(0deg, #000, transparent 75%),
      var(--background-image-url);
    background-size: cover;
    background-repeat: no-repeat;
    height: var(--item-height);
    width: var(--item-width);
    position: absolute;
    top: 0;
    left: 0;
  }
  &--hero-button {
    position: absolute;
    bottom: var(--space-bump);
    right: var(--space-bump);
  }
  &--hero-title {
    position: absolute;
    bottom: var(--space-bump);
    left: var(--space-bump);
    color: var(--white);
    max-width: 80%;
  }
  &--hero-subtitle {
    font-size: var(--text-size-default);
  }
  &--details-button {
    --button-fg: var(--brand-base);
    --button-padding: 0 0 var(--space-bump) 0;
    padding: 0 var(--horizontal-margin, var(--space-bump)) 0
      var(--horizontal-margin, var(--space-bump));
  }
  &--details {
    &--description {
      padding: 0 var(--horizontal-margin, var(--space-bump)) var(--space-nudge)
        var(--horizontal-margin, var(--space-bump));
    }
  }

  &--stats {
    margin: var(--space-nudge) 0;
    margin-left: var(--horizontal-margin, var(--space-bump));
    padding-right: var(--space-min);
    width: var(--item-width);
  }
  & + .workshop--item {
    margin-top: var(--space-default);
  }

  .rpm-hero {
    height: var(--item-height);
    width: var(--item-width);
    margin: var(--space-bump) var(--horizontal-margin, var(--space-bump)) 0
      var(--horizontal-margin, var(--space-bump));
    padding: 0;
  }
  .workshop--video {
    height: var(--item-height);
    width: var(--item-width);
    margin: var(--space-bump) var(--horizontal-margin, var(--space-bump)) 0
      var(--horizontal-margin, var(--space-bump));
  }

  overflow: hidden;
  background: var(--white);
  border: 1px solid var(--brand-gray-6);

  .rpm-icontext {
    justify-content: left;
  }
}

.swiper-slide:not(.swiper-slide-active) {
  .workshop--item {
    transform: scale(0.9);
    opacity: 0.6;
  }
}
