.app-panel {
  --loader-margin: var(--space-bump);
  --panel-bg: transparent;

  display: grid;
  grid-template-columns: var(--panel-columns, var(--panel-content-width, 4fr) 1fr);
  grid-template-areas: var(--panel-grid-areas, 'panel dismiss');
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  z-index: var(--panel-z-index, 25);
  background: var(--panel-bg, var(--brand-gray-7));
  color: var(--text-dark);
  will-change: background, transform;
  -webkit-backdrop-filter: blur(var(--local-backdrop-blur, 0));
  backdrop-filter: blur(var(--local-backdrop-blur, 0));
  transform: translate(var(--x, -100%), var(--y, 0%));
  transition:
    transform var(--base-transition-timing) ease-in-out,
    background var(--bg-timing, 700ms) linear var(--bg-delay, var(--base-transition-timing)),
    backdrop-filter var(--bg-timing, 700ms) linear var(--bg-delay, var(--base-transition-timing)),
    -webkit-backdrop-filter var(--bg-timing, 700ms) linear var(--bg-delay, var(--base-transition-timing));

  &--inner {
    grid-area: panel;
  }

  &--dismiss {
    grid-area: dismiss;
  }

  &.is-open {
    --x: 0%;
    --y: 0%;
    --panel-bg: var(--panel-open-bg, var(--lounge-lizard));
    --panel-box-shadow: 0 0 2em -1em var(--brand-black);
    --local-backdrop-blur: var(--backdrop-blur-min);
  }

  &.ui-dark {
    --panel-inner-bg: var(--text-dark);
    --panel-inner-fg: var(--text-light);
  }

  &:not(.is-open) {
    --bg-timing: 0ms;
    --bg-delay: 0ms;
  }

  &--inner {
    height: 100%;
    display: flex;
    flex-direction: column;
    background: var(--panel-inner-bg, var(--text-light));
    color: var(--panel-inner-fg, var(--text-dark));
    max-width: var(--panel-max-width, none);
    box-shadow: var(--panel-box-shadow, none);
    overflow-y: auto;
  }

  &--header {
    flex: none;
    position: sticky;
    top: 0;
    text-align: center;
    background-color: var(--brand-gray-0);
    color: var(--text-light);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 3.5em;
    z-index: 1;

    &--label {
      text-transform: uppercase;
      font-family: var(--font-heading);
      font-weight: 500;
      font-size: 1.2em;
      letter-spacing: .5vw;
    }
  }

  &--body {
    --local-bg: transparent;

    flex: 1;
    opacity: var(--opacity, 1);
    transition: opacity var(--base-transition-timing) ease-out;

    &.is-loading {
      --opacity: 0;
      pointer-events: none;
    }
  }

  &--footer {
    --button-fg: var(--brand-gray-3);
    --local-margin-top: 0;

    flex: none;
    position: sticky;
    bottom: 0;
    background: linear-gradient(var(--local-dialog-bg), var(--white));
    border-top: 1px solid var(--text-subtle);
    text-align: center;
    z-index: 1;
  }

  &--action {
    --button-position: absolute;
    --button-margin: 0;
    --button-radius: var(--radius-medium);
    --button-font-size: var(--text-size-small);
    --button-fg: var(--white);

    position: absolute;
    top: 50%;
    left: var(--space-bump);
    transform: translate(0, -50%);

    &--dismiss {
      left: auto;
      right: var(--space-bump);
    }
  }

  &--slide-in {
    &--right {
      --x: 100%;
      --y: 0%;
    }

    &--left {
      --x: -100%;
      --y: 0%;
    }

    &--top {
      --x: 0%;
      --y: -100%;
    }

    &--bottom {
      --x: 0%;
      --y: 100%;
    }
  }

  &--slide-out {
    &--right {
      --x: 100%;
      --y: 0%;
    }

    &--left {
      --x: -100%;
      --y: 0%;
    }

    &--top {
      --x: 0%;
      --y: -100%;
    }

    &--bottom {
      --x: 0%;
      --y: 100%;
    }
  }
}
