.login .app-main {
  --button-group-padding: var(--space-xxlarge) 0 0 0;
  --button-fg: var(--text-light);
  --group-align-items: baseline;
  --group-background: transparent;
  --peeker-bg: var(--brand-gray-7);

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  [role="alert"] {
    text-align: left;
    margin-bottom: var(--space-default);
  }

  .rpm-logo--atom {
    --logo-x-scale: .5;
  }

  .login-button--white {
    --local-button-fg: var(--black);
    --local-button-bg: var(--white);
    --button-text-transform: none;
  }

  .login--content {
    --max-width: 100%;

    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    text-align: center;
    padding: 0 var(--space-xxlarge);
  }

  h5 {
    --local-margin: var(--space-bump) auto;
  }

  h6 {
    margin: 0;
  }

  +.app-footer {
    --local-footer-bg: transparent;
  }

  .tos-checkbox {
    small {font-size: .7em;}
  }

  .rpm-button--link {
    --button-fg: white;
  }
}
