html {
  -webkit-text-size-adjust: 100%;
}

body,
.text--body {
  font-weight: 400;
  font-family: var(--font-body);
  font-size: var(--base-font-size);
  line-height: var(--root-line-height);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: inherit;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.text {
  &--link {
    --color: var(--ui-text-link, var(--text-link));
    --icon-color: var(--color);
    --button-fg: var(--color);
    color: var(--color);
  }

  &--danger {
    --color: var(--danger);
    --icon-color: var(--color);
    --button-fg: var(--color);
    color: var(--color);
  }

  &--small {
    --button-font-size: var(--scale--1);
    font-size: var(--scale--1);
  }

  &--smaller {
    font-size: var(--scale--2);
  }

  &--smallest {
    font-size: var(--scale--3);
  }

  &--large {
    --button-font-size: var(--scale-3);
    font-size: var(--scale-3);
  }

  &--normal {
    --font-weight: normal;
    --button-font-weight: var(--font-weight);

    font-weight: var(--font-weight);
  }

  &--bold {
    --font-weight: 900;
    --button-font-weight: var(--font-weight);

    font-weight: var(--font-weight);
  }

  &--700 {
    --font-weight: 700;
    --button-font-weight: var(--font-weight);

    font-weight: var(--font-weight);
  }

  &--underline {
    --button-text-decoration: underline;
    text-decoration: underline;
  }

  &--no-transform {
    text-transform: none;
  }

  &--monospace {
    font-family: var(--font-monospace);
    font-size: 0.9em;
  }

  &--caps {
    --local-button-text-transform: uppercase;
    text-transform: uppercase;
  }

  &--italic {
    font-style: italic;
  }

  &--no-style {
    font-style: normal;
  }

  &--center {
    text-align: center;
  }

  &--right {
    text-align: right;
  }

  &--left {
    text-align: left;
  }

  &--small-caps {
    text-transform: uppercase;
    font-size: 0.9em;
  }

  &--smaller-caps {
    text-transform: uppercase;
    font-size: 0.65em;
  }
}
