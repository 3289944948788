.profile {
  --local-margin-top: var(--space-medium);
  --button-group-padding: var(--space-xxlarge) 0;
  --group-margin-top: var(--space-medium);
  --group-background: transparent;
  --input-border-color: var(--text-link);
  --input-border: .07em solid var(--input-border-color);
  --input-group-items-border-left: none;

  width: 100%;
  max-width: 32rem;
  margin: 0 auto;
  justify-self: center;

  &:not(:empty) {
    padding: var(--local-padding, var(--block-padding));
  }

  h5 {
    --local-margin: 0 0 var(--space-default) 0;
  }

  &--footer {
    --button-group-padding: 0 0 var(--space-large) 0;

    grid-column: 1 / 3;
  }

  .avatar {
    --avatar-bg: var(--white);
  }

  &--image {
    --local-margin-top: var(--space-min);

    text-align: center;
    position: relative;
    grid-column: 1 / 3;

    &--delete {
      --button-fg: var(--danger);
    }
  }

  &--level-text {
    --local-margin-top: 0px;
    background: white;
    color: black;
  }
}

#profileImage {
  position: absolute;
  visibility: hidden;
  font-size: 0;
}
