@import url('https://fonts.googleapis.com/css2?family=Roboto+Black:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

@supports (backdrop-filter: blur(1em)) {
  :root {
    --backdrop-opacity-0: 0;
    --backdrop-opacity-1: 0.1;
    --backdrop-opacity-2: 0.2;
    --backdrop-opacity-3: 0.3;
    --backdrop-opacity-4: 0.4;
    --backdrop-opacity-5: 0.5;
    --backdrop-opacity-6: 0.6;
    --backdrop-opacity-7: 0.7;
    --backdrop-opacity-8: 0.8;
    --backdrop-opacity-9: 0.9;
    --backdrop-opacity-10: 1;

    --backdrop-blur-none: 0;
    --backdrop-blur-min: 0.3em;
    --backdrop-blur-small: 0.5em;
    --backdrop-blur-default: 1em;
    --backdrop-blur-large: 1.5em;
    --backdrop-blur-max: 2em;
  }
}

// @supports (font: -apple-system-body) {
//   html {
//     font: -apple-system-body;
//   }
// }

:root {
  --base-font-size: 4.2vmin;
  --base-transition-timing: 300ms;
  --root-line-height: 1.4;
  --scale-base: var(--base-font-size);

  --scale-7: 4.768rem;
  --scale-6: 3.052rem;
  --scale-5: 2.441rem;
  --scale-4: 1.953rem;
  --scale-3: 1.563rem;
  --scale-2: 1.322rem;
  --scale-1: 1rem;
  --scale-0: 0rem;
  --scale--1: 0.8rem;
  --scale--2: 0.64rem;
  --scale--3: 0.512rem;
  --scale--4: 0.41rem;
  --scale--5: 0.328rem;
  --scale--6: 0.262rem;

  --space-max: var(--scale-7);
  --space-xxlarge: var(--scale-6);
  --space-xlarge: var(--scale-5);
  --space-large: var(--scale-4);
  --space-medium: var(--scale-3);
  --space-bump: var(--scale-2);
  --space-default: var(--scale-1);

  --space-none: var(--scale-0);

  --space-small: var(--scale--1);
  --space-nudge: var(--scale--2);
  --space-xsmall: var(--scale--3);
  --space-xxsmall: var(--scale--4);
  --space-min: var(--scale--5);

  --negative-space-min: calc(var(--scale--5) * -1);
  --negative-space-xxsmall: calc(var(--scale--4) * -1);
  --negative-space-xsmall: calc(var(--scale--3) * -1);
  --negative-space-nudge: calc(var(--scale--2) * -1);
  --negative-space-small: calc(var(--scale--1) * -1);
  --negative-space-default: calc(var(--scale-1) * -1);
  --negative-space-bump: calc(var(--scale-2) * -1);
  --negative-space-medium: calc(var(--scale-3) * -1);
  --negative-space-large: calc(var(--scale-4) * -1);

  --text-size-max: var(--scale-7);
  --text-size-xxlarge: var(--scale-6);
  --text-size-xlarge: var(--scale-5);
  --text-size-large: var(--scale-4);
  --text-size-medium: var(--scale-3);

  --text-size-bump: var(--scale-2);
  --text-size-default: var(--scale-1);
  --text-size-none: var(--scale-0);
  --text-size-small: var(--scale--1);
  --text-size-nudge: var(--scale--2);
  --text-size-xsmall: var(--scale--3);
  --text-size-xxsmall: var(--scale--4);
  --text-size-min: var(--scale--5);

  --radius-a-skosh: 0.1em;
  --radius-default: 0.2em;
  --radius-bump: 0.35em;
  --radius-medium: 0.5em;
  --radius-large: 1em;
  --radius-xlarge: 2em;
  --radius-full-circle: 50%;

  --font-body: 'Roboto', Helvetica, Arial, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol';
  --font-system: -apple-system, BlinkMacSystemFont, 'Segoe UI', var(--font-body);
  --font-icon: 'Font Awesome 5 Brands', 'Font Awesome 5 Pro', var(--font-body);
  --font-heading: 'Roboto Black', var(--font-body);
  --font-monospace: source-code-pro, Menlo, Monaco, Consolas, monospace,
    var(--font-icon);

  --block-padding: var(--space-default) var(--space-bump);
  --block-padding-topless: 0 var(--space-bump) var(--space-default)
    var(--space-bump);
  --header-width: 100vw;
  --header-ratio: 0.5625;
  --video-height-ratio: 0.5625;

  --media-max-width: calc(
    (100vw - (var(--space-bump) * 2) - (var(--media-items, 1) - 1) * 1em) /
      var(--media-items, 1)
  );
  --media-max-height: calc(var(--media-max-width) * var(--video-height-ratio));

  --opacity-ghost: 0.4;
  --opacity-ish: 0.6;
}
