.rpm-pager {
  --button-fg: var(--text-ish);
  --local-padding: 0;
  --local-margin-left: 0;
  --local-flex: none;

  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;

  button {
    flex: none;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: var(--radius-default);
    line-height: 1;
    width: var(--space-large);
    height: var(--space-large);
  }

  .is-selected {
    --button-bg: var(--brand-base);
    --button-fg: var(--text-light);
  }
}
