.app-comments {
  --user-row-bg: var(--brand-gray-7);
  --panel-inner-bg: var(--brand-black);

  &--header,
  &--user,
  &--input {
    flex: none;
  }

  &--row {
    display: grid;
    grid-template-columns: 3.5em 1fr;
    grid-gap: var(--space-small);
    align-items: var(--row-align-items, baseline);
    padding: var(--block-padding);
    padding-top: var(--row-padding-top, 0);

    &.is-prime {
      --row-padding-top: var(--space-small);
      --row-align-items: center;
      --details-padding-top: 0;
      --details-border-top: none;

      border-bottom: 1px solid var(--brand-gray-6);
    }

    &:first-child {
      --details-border-top: none;
    }

    h3,
    p {
      margin: var(--local-margin, 0);
    }
  }

  &--user {
    --local-align-items: baseline;
  }

  &--avatar {
    justify-self: center;
  }

  &--delete {
    --icon-color: var(--brand-base);
  }

  &--username {
    --button-fg: var(--text-dark);
    font-weight: bold;
  }

  &--comment {
    --local-margin: var(--space-min) 0 0 0;
  }

  &--details {
    --local-margin-top: var(--space-min);

    border-top: var(--details-border-top, 1px solid var(--brand-gray-6));
    padding-top: var(--details-padding-top, var(--space-default));
  }

  &--input {
    --group-margin-top: 0;
    --group-border-radius: var(--radius-default);
    --group-gap: var(--space-default);
    --group-align-items: start;
    --group-background: transparent;
    --input-border: 0.07em solid var(--brand-gray-3);

    position: sticky;
    bottom: 0;
    background: linear-gradient(var(--brand-gray-7), var(--white));
    border-top: 1px solid var(--brand-gray-6);
    padding: var(--space-default);
  }

  a {
    text-decoration: none;
  }

  .text--section-notes {
    --local-margin-top: var(--space-default);

    font-size: var(--text-size-default);
    white-space: pre-wrap;
    margin: 0 0 var(--space-default) var(--space-small);
  }

  .app-panel--body {
    --local-bg: var(--white);
  }

  .rpm-hero {
    --hero-line-height: var(--root-line-height);
    --header-width: var(--panel-content-width);
    --header-padding: 0;
    --header-height: unset;
    --local-padding: var(--space-medium);

    height: unset;

    background-image: linear-gradient(180deg, rgba(#000, 0.4), rgba(#000, 1)),
      var(--header-bg-image);
    background-repeat: no-repeat;
    background-size: var(--header-background-size, contain);
    background-position: top;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }

  .rpm-workout-link {
    position: relative;
    color: var(--text-light);
    margin-top: var(--space-small);
  }

  .rpm-training-score {
    padding: var(--space-medium);

    position: absolute;
    right: 0;
    bottom: 0;
  }

  .rpm-workout {
    padding: var(--space-small) 0;

    overflow: auto;

    .rpm-markdown {
      display: inline;
    }

    .workout-section {
      padding: 0 var(--space-medium);
      margin-bottom: var(--space-default);

      &--header {
        --button-font-size: var(--text-size-default);

        button {
          grid-gap: var(--space-default);
        }

        padding: var(--space-default) var(--space-medium);
        background-color: var(--text-dark);
        font-size: var(--text-size-default);
      }

      &--title {
        padding-top: 0;
      }

      &--workout {
        margin-bottom: var(--space-large);
      }

      .workout-overlay--movement {
        grid-gap: var(--space-default);
        margin-bottom: var(--space-default);
        font-size: var(--text-size-default);

        &--result {
          margin-left: var(--space-small);
        }
      }
    }
  }

  .user--row--avatar {
    align-self: start;
  }

  footer {
    .search--results {
      display: flex;
      text-align: left;
      background: var(--brand-gray-0);
      --button-fg: var(--white);
      color: var(--white);

      .search--result--details p {
        margin: 0px;
      }

      ul {
        width: 100%;

        li.is-selected {
          background: var(--brand-base);
        }
      }
    }
  }
}
