.rpm-flyout {
  --fg: var(--text-dark);
  --bg: var(--white);

  background: var(--bg);
  color: var(--fg);
  position: var(--flyout-position, relative);
  user-select: none;

  button:not(.rpm-button--shield) {
    font-size: inherit;
    font-family: inherit;
    color: inherit;
    padding: .75em;
    margin: 0;
    border: none;
    background: transparent;
    text-align: var(--text-align, left);
    cursor: pointer;
    width: var(--flyout-button-width, 100%);
  }

  &:not([open]) {
    --content-display: none;
  }

  &[open] {
    --max-height: 60vh;
    z-index: 4;
  }

  &--label {
    --text-align: center;

    background: var(--bg);
    color: inherit;
    position: relative;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
    white-space: nowrap;
    z-index: 3;
    height: 3em;
  }

  &--content {
    background: var(--bg);
    position: absolute;
    left: 0;
    right: 0;
    top: var(--local-top, 100%);
    max-height: var(--max-height, 60vh);
    margin: 0;
    padding: var(--local-padding, var(--block-padding));
    display: var(--content-display, block);
    overflow: auto;
    z-index: 2;
  }

  .rpm-input--group--checkbox {
    .rpm-input--label {
      --input-label-font-size: 1em;
      --input-label-font-weight: bold;
      --input-label-opacity: 1;
    }
  }
}
