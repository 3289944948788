.rpm-input--group {
  --input-group-text-align: center;
  --columns: repeat(var(--cols, 2), 1fr);

  &:not(:first-child) {
    margin-top: var(--group-margin-top, var(--space-default));
  }

  &--date {
    --input-display: inline-block;
  }

  &--time {
    text-align: center;
  }

  &--items {
    --input-border-radius: var(--group-border-radius, 0);
    --input-margin-top: 0;

    display: var(--group-display, grid);
    grid-template-columns: var(--columns);
    grid-gap: var(--group-gap, 0);
    align-items: var(--group-align-items, stretch);
    justify-content: var(--group-justify-content, space-around);
    background-color: var(--group-background, var(--white));
    color: var(--brand-gray-0);
    position: relative;
    border: var(--group-border, none);

    > *:first-child {
      --input-border-radius: var(
        --group-border-radius,
        var(--radius-default) 0 0 var(--radius-default)
      );
    }

    > *:last-child {
      --input-border-radius: var(
        --group-border-radius,
        0 var(--radius-default) var(--radius-default) 0
      );
    }

    &:not(.has--gap) > label + label {
      --input-border-left: none;
    }
  }

  &--radio {
    --group-background: var(--white);
  }

  .rpm-input,
  .rpm-input--group {
    margin-top: 0;
  }

  .rpm-input--group {
    --input-group-text-align: center;
    --input-border-color: var(--text-link);
  }

  &.is-list {
    --group-display: block;
  }

  &.is-horizontal-list {
    --group-display: flex;
    --group-justify-content: flex-start;
    --group-background: transparent;
    --group-gap: var(--space-default);
    --input-group-items-border-left: none;
  }

  &.is-bar {
    .rpm-input--control {
      --scale-x: 0;
      --scale-y: 1;
      --input-display: block;

      flex: 1 1 auto;
      position: relative;
      text-align: var(--input-group-text-align, left);
      padding: var(--group-input-padding, var(--input-padding));
      border: var(--input-border, none);
      border-left: var(--input-border-left, var(--input-border));
      border-radius: var(--input-border-radius, var(--radius-default));

      input {
        opacity: 0;
        position: absolute;

        &:checked + span {
          --scale-x: 1;
        }

        ~ span {
          font-weight: var(--font-weight, 400);

          &::after {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            height: var(--space-min);
            background: var(--brand-base);
            transform: scale(var(--scale-x), var(--scale-y));
            transition: 200ms ease-out;
          }
        }
      }
    }
  }

  &.is-disabled {
    cursor: not-allowed;
  }
}
