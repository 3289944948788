.leaderboard--row {
  --local-margin-top: 0;
  --button-fg: var(--brand-gray-0);

  padding: var(--row-padding, var(--block-padding));
  display: grid;
  grid-template-columns: minmax(2ch, auto) auto 1fr;
  align-items: baseline;
  grid-gap: var(--space-small);
  color: var(--text-dark);

  &.is-me {
    // --result-fg: var(--aqua-base);
    // --avatar-bg: var(--brand-base);
    position: sticky;
    top: calc(var(--height-of-filters, 0) + var(--height-of-subheader, 0));
    bottom: 0;
    background: rgba(var(--white-rgb), var(--backdrop-opacity-6, 1));
    -webkit-backdrop-filter: blur(var(--backdrop-blur-default));
    backdrop-filter: blur(var(--backdrop-blur-default));
    z-index: 1;

    &:not(:only-child) {
      border-bottom: 1px solid var(--brand-gray-6);
      margin-bottom: -1px;
    }
  }

  &.show-more {
    --comment-display: block;
  }

  &--rank {
    text-align: center;
    color: var(--result-fg, inherit);
  }

  &--score {
    color: var(--result-fg, inherit);
  }

  &--user {
    --button-font-weight: bold;

    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }

  &--stats {
    line-height: 1;
  }

  +.leaderboard--row {
    border-top: 1px solid var(--brand-gray-6);
  }
}
