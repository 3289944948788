.search--results {
  --local-margin-top: 0;

  &.is-open {
    --item-display: block;
    --item-padding: var(--block-padding);
    --list-display: block;
    --list-padding: 0;
    --item-margin-shift: 0;
    --item-box-shadow: none;
  }

  ul {
    list-style: none;
    margin: 0;
    display: var(--list-display, flex);
    transition: var(--base-transition-timing) ease-out;
    flex-flow: row wrap;

    &:not(:empty) {
      padding: var(--list-padding, var(--space-default) var(--space-bump) var(--space-xlarge) var(--space-bump));
    }
  }
}

.search--result {
  display: flex;
  padding: var(--item-padding, 0);
  grid-gap: var(--space-default);
  align-items: center;
  transition: var(--base-transition-timing) ease-out;

  .avatar {
    box-shadow: var(--item-box-shadow, -1px 1px .2em -1px var(--white), -.5em .5em 1em -.7em var(--brand-black));
    transition:
      width var(--base-transition-timing) ease-out,
      height var(--base-transition-timing) ease-out,
      box-shadow var(--base-transition-timing) ease-out;
  }

  &--avatar {
    margin-right: var(--item-margin-shift, var(--negative-space-bump));
    margin-bottom: var(--item-margin-shift, var(--negative-space-bump));
  }

  &--details {
    flex: 1;
    display: var(--item-display, none);
  }

  &--action {
    --button-text-decoration: none;
    display: var(--item-display, none);

    button {
      padding: var(--space-small);
      margin: var(--negative-space-small);
    }
  }
}
