.training {
  .journal--component {
    --filters-top: 54px;

    padding-bottom: var(--space-small);
    z-index: 0;

    #search {
      border: none;
    }

    .journal--filters {
      position: static;
    }

    &--visible {
      .journal--filters {
        position: sticky;
      }
    }
  }

  --header-padding: 0;
  --header-height: calc(var(--header-width) * var(--header-ratio));

  &--header {
    grid-area: header;
  }

  &--filters {
    --filter-gap: 0px;
    grid-area: filters;

    .training--editing-status {
      padding: var(--space-xxsmall) var(--space-bump);
    }
  }

  &--content {
    grid-area: content;
  }

  &--workouts {
    grid-area: workouts;
  }

  &--details {
    grid-area: details;
  }

  &--segments {
    grid-area: segments;
  }

  &--profile {
    grid-area: profile;
  }

  &--athletes {
    grid-area: athletes;
  }

  &--products {
    grid-area: aside;
  }

  &--log-buttons {
    --local-template-columns: 0.5fr 2fr 0.5fr;
    --button-justify: center;

    position: sticky;
    bottom: 0;
    border-top: 1px solid var(--brand-gray-6);
    z-index: 3;

    .small-icon {
      --local-button-border: 2px solid var(--brand-base);
      --button-radius: var(--radius-default);
      --button-margin: var(--space-min);
    }

    .small-icon--danger {
      --local-button-border: 2px solid var(--danger);
      --button-radius: var(--radius-default);
      --button-margin: var(--space-min);
      --local-button-bg: var(--danger);
      --button-fg: var(--white);
    }

    &--edit {
      .small-icon {
        --local-button-border: 2px solid var(--brand-gray-5);
      }
    }

    &--one-column {
      display: none;
    }

    &--full-width {
      display: grid;
    }
  }

  &--journal-embed {
    --input-border-radius: 0;

    background-color: var(--local-bg, var(--white));
    padding: 0;
    .button--expand-journal {
      border-top: 1px solid var(--brand-gray-6);
      position: sticky;
      bottom: 0;
      justify-content: center;
      width: 100%;
      background-color: white;
      color: var(--brand-base);
      padding: var(--space-min) 0;
    }
  }

  &--header {
    color: var(--brand-gray-7);

    .poster {
      background-image: linear-gradient(
          100deg,
          rgba(#000, 1),
          rgba(#000, 0) 65%
        ),
        var(--image-url);
      width: 100%;
      height: 100%;
      background-size: cover;
      background-position: 50% 40%;
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      transition: opacity var(--base-transition-timing) ease-in-out;
      backface-visibility: hidden;
      -webkit-backface-visibility: hidden;
    }

    .active {
      opacity: 1;
      transition: opacity 500ms ease-in-out;
    }

    &--cover {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
    }

    &--details {
      display: grid;
      grid-template-rows: 1fr auto;
      align-items: start;
      position: relative;
      height: 100%;
      padding: var(--block-padding);
      padding-top: var(--space-large);
    }

    &--location {
      font-size: var(--text-size-small);
      text-transform: none;
    }

    &--title {
      --local-margin-top: 0.1rem;
      font-size: var(--text-size-large);
      line-height: 0.95;
    }

    &--subtitle {
      font-size: var(--text-size-medium);
      line-height: 1.05;
    }

    &--coach {
      --local-margin-top: 0.2rem;
      font-weight: bold;
    }

    &--star {
      --local-margin-top: 0.3rem;
    }

    &--coach,
    &--star {
      font-size: var(--text-size-small);
    }

    &--carousel {
      --button-fg: rgba(var(--white-rgb), 0.35);

      position: absolute;
      display: grid;
      grid-template-columns: repeat(auto-fit, 1em);
      grid-gap: var(--space-min);
      bottom: var(--space-bump);
      left: 50%;
      transform: translate(-50%, 0%);
      max-width: 60%;

      .is-selected {
        --button-fg: rgba(var(--white-rgb), 1);
      }
    }

    &--video-cue {
      position: absolute;
      left: 0;
      bottom: 0;
      padding: var(--block-padding);
    }
  }

  &--workouts {
    --local-bg: var(--white);
    --tooltip-top: calc(
      var(--tooltip-y) - var(--header-height, 0) - var(--height-of-filters, 0) -
        3.5em
    );

    position: relative;
    background: var(--local-bg, var(--white));
    overflow: hidden;
  }

  &--format {
    --local-margin-top: 0;
    padding: var(--format-padding, var(--block-padding));

    &--title {
      font-weight: 500;
    }

    &--subtitle {
      font-family: var(--font-body);
      font-weight: 400;
      text-transform: none;
      color: var(--brand-gray-3);
    }

    &--notes {
      border-top: 0.07em solid var(--brand-gray-6);
      font-family: var(--font-body);
      font-weight: normal;
      text-transform: none;
      background: var(--brand-gray-7);

      p:first-child {
        padding-top: 0;
      }
      p {
        padding-top: var(--space-min);
      }

      &--button {
        color: var(--brand-base);
      }
    }

    &--tracking {
      --input-border-color: var(--brand-gray-3);

      padding-top: 0;

      input:focus {
        border-color: var(--brand-base);
      }
    }
  }

  &--section-header {
    --block-bg: black;
    color: var(--text-light);
    text-transform: uppercase;
    font-weight: bold;
  }

  &--movement {
    --rotate: rotate(0deg);
    --local-sup-margin-left: 1ch;

    padding: 0;
    // border-top: 0.07em solid var(--brand-gray-6);
    line-height: 1.3;

    & > div {
      //padding-left: var(--space-small);
      padding-top: var(--space-small);
    }

    & > div:last-child {
      padding-bottom: var(--space-small);
    }

    sup {
      font-weight: normal;
      white-space: nowrap;
    }

    &.is-static {
      --summary-padding: var(--space-min) var(--space-bump);
      pointer-events: none;
    }

    &--details {
      --button-font-size: 1em;

      transition: var(--base-transition-timing) linear;

      &:last-child {
        --content-margin-bottom: var(--negative-space-small);
      }

      &[open] {
        --rotate: rotate(90deg);
        --summary-color: var(--brand-gray-3);
        --content-bg: var(--brand-gray-7);
        --summary-font-style: italic;
      }

      // specific to the HTML summary tag
      summary {
        cursor: pointer;
        outline: none;

        // gecko/mozilla
        list-style: none;

        // blink/webkit
        &::-webkit-details-marker {
          display: none;
        }
      }

      .marker {
        --icon-color: var(--text-dark);

        transform: var(--rotate);
        transition: transform 200ms ease-in-out;

        &::before {
          display: inline-block;
        }
      }

      .demo-cue {
        padding: 0 var(--space-xsmall);
        margin: 0 calc(var(--space-small) * -1);
      }

      &.is-rest {
        --summary-color: var(--brand-gray-2);
        background-color: var(--brand-gray-6);
        margin: var(--summary-padding, var(--space-xsmall) var(--space-bump));
        text-align: center;
      }

      &.is-nested {
        --summary-padding: 0;
        --content-padding: var(--space-small) 0;

        padding-right: var(--space-bump);

        &:not([open]) {
          --rotate: rotate(0deg);
          --summary-color: initial; // var(--brand-gray-3);
          --summary-font-style: normal;
        }

        li:not(.is-selected) {
          --title-font-weight: normal;
        }
      }
    }

    &--summary {
      color: var(--summary-color, inherit);
      margin-bottom: var(--summary-margin-bottom, 0);
      padding: var(--summary-padding, var(--space-xsmall) var(--space-bump));
      transition: var(--base-transition-timing) linear;

      > * {
        display: grid;
        grid-template-columns: 1fr auto;
        align-items: baseline;
      }

      .summary-title {
        display: var(--summary-title-display, inline-block);
        font-style: var(--summary-font-style, normal);
        // transition: color var(--base-transition-timing) linear;
        .rpm-markdown {
          display: inline;
        }
      }

      .training--movement--summary {
        padding: 0;
      }
    }

    &--content {
      margin: var(--content-margin, 0);
      margin-bottom: var(--space-min);
      background: var(--content-bg, var(--white));
      border-radius: var(--content-radius, 0);
      box-shadow: var(--content-edge, none);
      padding: 0;

      .rpm-markdown {
        display: inline;
      }

      li {
        --button-display: block;

        display: grid;
        grid-template-columns: var(--space-bump) 1fr auto;
        grid-gap: var(--space-min);
        align-items: baseline;
        padding: var(--content-padding, var(--space-small) var(--space-bump));

        &.has-options {
          grid-template-columns: var(--space-bump) 1fr;
        }

        &.is-selected {
          --title-font-weight: bold;
          --button-font-weight: bold;
          --level-bg: var(--brand-base);
          --level-fg: var(--white);
          background-color: var(--brand-lighten-3);
        }

        &:not(.is-selected) {
          color: var(--text-dark);
        }

        + li {
          padding-top: var(--space-default);
        }
      }
    }

    &--title {
      grid-gap: var(--space-small);
      font-weight: var(--title-font-weight, normal);
    }

    &--subtitle {
      color: var(--brand-gray-1);
    }

    &--previous {
      color: var(--brand-gray-3);
    }

    .rpm-submit-score section {
      padding: 0;
    }

    .training--format {
      --input-border: 0.07em solid
        var(--input-border-color, var(--brand-gray-3));

      padding-top: var(--space-small);
      padding-bottom: 0;

      &--notes {
        padding-left: var(--space-bump);
        margin: var(--space-small) 0 0 0;
      }

      &.rpm-details-block {
        --details-bg: var(--local-bg);
        padding-top: 0;
        padding-bottom: 0;
      }

      &.form-area--section-notes {
        padding-top: 0;

        textarea {
          resize: none;
        }
      }
    }

    .training--form-review {
      --button-font-size: 0.9em;
      --button-text-decoration: none;
      --local-button-text-transform: uppercase;

      // border-top: 0.07em solid var(--brand-gray-6);
      // border-bottom: 0.07em solid var(--brand-gray-6);
      padding: var(--format-padding, var(--block-padding));
      margin-top: var(--space-small);

      &--details {
        padding-top: var(--space-small);
      }

      .form-icon {
        padding-right: var(--space-medium);
      }
    }
  }

  &--leaderboard {
    text-align: right;
    display: grid;
    grid-template-columns: 1fr 0.5fr auto;
    grid-gap: none;
    align-items: center;
    line-height: 1;
    b {
      small {
        padding-right: var(--space-xxsmall);
      }
    }
    .training--timecap {
      text-align: left;
    }
    .rpm-picklist {
      --text-align: right;
      &--label {
        --text-align: right;
        background: var(--header-block-bg, var(--brand-gray-7));
        height: 1em;
        button {
          padding: var(--space-xxsmall);
        }
      }
    }
  }

  &--buttons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1em;
  }

  &--results {
    --local-border-width: 0;

    &--embedded {
      margin: 0rem 1rem 1rem 1rem;
    }
  }

  &--info-block {
    --local-margin-top: var(--space-min);

    ul {
      padding: 0 var(--space-medium);
      margin: var(--space-min) 0 0 0;
      line-height: var(--root-line-height);
    }

    li + li {
      margin-top: var(--space-min);
    }
  }

  &--scoring-notes {
    --block-bg: var(--white);
    border: none;
    padding-bottom: 0;
  }

  &--video-segments {
    padding: var(--block-padding);
    background: linear-gradient(
      var(--brand-gray-7) 0,
      var(--white) var(--space-max)
    );

    .icon--video-play {
      --button-font-size: 1.2em;
    }

    li {
      --button-padding: var(--space-xsmall) 0;

      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      align-items: center;
      text-align: right;

      + li {
        border-top: 1px solid var(--brand-gray-7);
      }
    }
  }

  &--featured-athletes {
    border-top: 1px solid var(--brand-gray-6);
  }

  .rpm-dialog--confirmation {
    --dialog-max-width: 90%;
  }

  &--dialog {
    &--demo-video {
      --dialog-width: 90%;

      h3 {
        font-size: 1em;
        font-weight: 400;
        text-transform: capitalize;
      }

      .brightcove-react-player-loader,
      .videojs-player {
        --dialog-content-padding: 0;
      }
    }
  }

  &--panel {
    &--score-tools {
      --panel-inner-bg: transparent;
      --panel-columns: 1fr;
      --panel-content-width: 80%;
      --panel-max-width: 18em;
      --button-font-weight: 400;
      --button-text-transform: none;
      --button-font-size: 1em;

      display: flex;
      align-items: var(--score-panel-flex-align, flex-end);
      justify-content: center;

      &.is-open {
        --local-box-shadow: none;
      }

      .app-panel--inner {
        margin: 0 auto;
        width: 80%;
        height: auto;
        box-shadow: var(--local-box-shadow, none);
      }

      .app-panel--body {
        background: var(--text-light);
        border-radius: var(--radius-medium);
        box-shadow: var(--panel-box-shadow, none);

        ul {
          list-style: none;
          margin: 0;
          padding: 0;

          li + li {
            border-top: 1px solid var(--brand-gray-6);
          }
        }
      }

      .app-panel--action {
        --button-position: relative;
        --button-margin: var(--space-default) 0;
        --button-radius: var(--radius-medium);
        --button-font-size: var(--text-size-default);
        --button-fg: inherit;
        --local-button-justify: center;

        position: relative;
        left: auto;
        top: auto;
        right: auto;
        transform: none;
        background: var(--text-light);
        padding: var(--block-padding);
        border-radius: var(--radius-medium);
        box-shadow: var(--panel-box-shadow, none);
      }
    }
  }

  .leaderboard--row {
    --row-padding: var(--space-default) 0;
  }
}
