.dashboard-slider {
  --swiper-navigation-color: var(--white);
  --swiper-navigation-sides-offset: 0.25em;

  &--swiper {
    margin-bottom: var(--space-min);
  }
  &--details {
    --local-align-items: normal;
  }

  .swiper-button-next,
  .swiper-button-prev {
    transform: scale(0.7);
  }
}
