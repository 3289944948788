.rpm-media {
  &--grid {
    padding: var(--media-grid-padding, var(--block-padding));
    display: flex;
    flex-flow: row wrap;
    gap: var(--media-grid-gap, 0);
    margin: var(--media-grid-margin, 0);

    > * {
      flex: 1 0 var(--media-max-width);
    }
  }

  &--item {
    --button-align-items: stretch;
    --button-justify: space-between;
    --button-bg: transparent var(--image);
    --button-fg: var(--text-light);
    --button-padding: 0;
    --button-radius: var(--radius-bump);

    max-width: var(--media-max-width);
    height: var(--media-max-height);
    display: flex;

    &--info {
      flex: 1;
      width: 100%;
      height: 100%;
      padding: 1em;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      background-image: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0.4) 0,
        rgba(0, 0, 0, 0.7) 3.5em,
        rgba(0, 0, 0, 0) 3.5em
      );
    }
  }
}
