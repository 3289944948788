.onboarding {
  --local-bg: var(--brand-gray-0);

  .app-main {
    --input-error-color: var(--red-lighten-3);

    .tab-content-profile {
      --local-bg: var(--brand-gray-7);
      --local-fg: var(--text-dark);
      --max-width: 100%;
    }

    .tab-content-assessment,
    .tab-content-kit {
      --button-fg: var(--text-light);
    }

    .profile--image--delete {
      --button-fg: var(--text-light);
    }

    +.app-footer {
      --local-footer-bg: transparent;
    }
  }
}
