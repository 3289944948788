.rpm-form {
  --caret-size: .7em;
  --caret-bg: var(--brand-gray-7);
  --background: var(--white);

  flex: 1;

  &--unit {
    display: inline-flex;
    align-items: flex-end;
  }

  &--section {
    &--footer {
      --local-margin-top: var(--space-min);
      --button-fg: var(--brand-gray-3);

      border-top: 1px solid var(--brand-gray-6);
      text-align: center;

      button {
        flex: 1;
      }
    }
  }

  input,
  select {
    font-size: 1em;
    font-family: inherit;
    border: .05em solid var(--brand-gray-3);
    border-radius: .2em;
    outline: none;
    line-height: 2;
    background: var(--background);

    &:focus {
      border-color: var(--brand-base);
      outline: none;
    }

    &:not([size]) {
      width: 100%;
    }
  }

  input {
    padding: 0 .5em;
  }

  select {
    appearance: none;
    padding: 0 calc(var(--caret-size) + 1.5em) 0 .5em;

    background-image:
      url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 11' width='20' height='11'><polyline points='1 1 10 10 19 1' fill='transparent' stroke-width='3' stroke='%23000000' /></svg>"),
      linear-gradient(90deg, var(--background) calc(100% - (var(--caret-size) * 3)), var(--caret-bg) calc(100% - (var(--caret-size) * 3)));
    background-size: var(--caret-size);
    background-size: var(--caret-size), 100%;
    background-position: calc(100% - var(--caret-size)) 50%;
    background-position: calc(100% - var(--caret-size)) 50%, 0% 50%;
    background-repeat: no-repeat;

    &[disabled] {
      --caret-size: 0em;
      --caret-bg: #eee;
      --background: #eee;
      color: #777;
    }
  }
}
