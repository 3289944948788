*,
*::before,
*::after {
  box-sizing: border-box;
}

@import 'variables-colors';
@import 'variables';
@import 'font-settings';
@import 'layouts-and-structures';
@import 'utilities';
@import 'elements';
@import 'molecules';
@import 'inputs';
@import 'screens';
@import 'routes';
@import 'responsiveness';

html {
  height: 100%;
}

body {
  --shade: rgba(0, 0, 0, var(--bg-screen, 0.35));

  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin: 0;
  color: var(--white);
  background-color: var(--local-bg, var(--brand-black));
  background-position: bottom center;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-image: linear-gradient(var(--shade), var(--shade)), var(--bg-image);
}

code {
  font-family: var(--font-monospace);
}

#root {
  flex: 1;
  display: grid;
  grid-template-rows: auto 1fr auto;
  grid-template-columns: 100vw;
  grid-template-areas:
    'header'
    'main'
    'aside'
    'footer';
}

.textfield input {
  color: var(--brand-gray-0);
}
