.rpm-flipcard {
  perspective: 1000px;
  width: 100%;
  height: auto;

  .flip-content {
    transform: rotateY(180deg);
  }

  .rpm-flipcard--content {
    position: relative;
    width: 100%;
    aspect-ratio: 1/1;
    // height: auto;
    transform-style: preserve-3d;
    transition: transform 0.6s;
    pointer-events: none;

    &--front {
      width: 100%;
      height: 100%;
      backface-visibility: hidden;
      transform: rotateY(0deg);
      pointer-events: all;
    }

    &--back {
      width: 100%;
      height: 100%;
      backface-visibility: hidden;
      position: absolute;
      top: 0;
      left: 0;
      transform: rotateY(180deg);
      pointer-events: all;
    }
  }
}

.rpm-flipcard--on-hover {
  &:hover {
    .rpm-flipcard--content {
      transform: rotateY(180deg);
    }
  }
}
