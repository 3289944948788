.affiliates {
  &--filters {
    grid-area: filters;
  }

  &--content {
    grid-area: content;
  }

  &--resources {
    ul {
      list-style: none;
      margin: 0;
      padding: var(--space-default) 0 0 0;

      li + li {
        padding-top: var(--space-default);
      }
    }
  }

  &--metrics {
    font-size: 0.8em;
    font-weight: 600;
    --button-font-weight: 600;
    --button-cursor: help;
  }

  &--overview {
    --media-grid-padding: 0.1em;

    &--details {
      --details-bg: var(--white);
      padding-bottom: var(--space-bump);

      .details--expander {
        padding: 0 var(--space-bump);
      }
    }

    &--summary-row {
      display: grid;
      grid-template-columns: 4em 1fr;
      grid-gap: 0.1em;
      position: relative;
      border-radius: 0.3em;
      overflow: hidden;

      > * {
        background: var(--brand-gray-7);
        padding: var(--block-padding);

        &.date {
          --icon-color: var(--color);
          --row-icon-fg: var(--text-link);

          text-align: center;
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: var(--space-default) 0;
          gap: 0.5ch;
          text-decoration: none;
        }
      }

      + * {
        margin-top: 0.1em;
      }
    }

    footer {
      --local-justify-content: flex-end;
    }
  }

  &--library {
    --media-grid-gap: 1em;

    &--filters {
      --local-filter-cols: 2;
      --group-border-radius: 0;
      --search-grid-column: 2;
      --search-grid-row: 1;

      .rpm-input--control--search {
        border-left: var(
          --input-group-items-border-left,
          1px solid var(--brand-gray-6)
        );
      }
    }
  }

  &--staff {
    &--filters {
      --local-filter-cols: 1;
      --group-border-radius: 0;
      --search-grid-column: 2;
      --search-grid-row: 1;

      .rpm-input--control--search {
        border-left: var(
          --input-group-items-border-left,
          1px solid var(--brand-gray-6)
        );
      }
    }

    .user--row {
      --local-align-items: baseline;

      &.is-member {
        --color: var(--brand-base);
      }
      &.is-coach {
        --color: var(--accent-base);
      }
      &.is-admin {
        --color: var(--purple-base);
      }

      .member-type {
        color: var(--color);
      }
    }

    .rpm-input--control--search {
      --input-background: none;
      --local-input-padding: var(--space-min) 0;
      --button-align-items: baseline;

      margin: var(--negative-space-min) 0;
    }
  }

  .app-content--inner {
    --local-margin-top: 0;

    > div {
      --local-padding: var(--block-padding);

      + div {
        border-top: 1px solid var(--brand-gray-6);
      }
    }

    h3,
    h4,
    h5 {
      &:not(:first-child) {
        margin-top: var(--space-bump);
      }

      + * {
        --local-margin-top: 0;
        --unlist-margin: var(--local-margin-top) 0 0 0;
      }

      + p {
        --local-margin-top: 0;
      }
    }
  }

  .expander {
    --button-text-decoration: none;
    --button-display: flex;

    gap: 1ch;
  }

  details {
    border-radius: var(--radius-default);
    transition: background var(--base-transition-timing) linear;
    margin: 0 var(--negative-space-min);

    &[open] {
      --rotate: rotate(90deg);

      background: var(--brand-gray-7);
    }
  }

  summary {
    cursor: pointer;
    outline: none;
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    gap: var(--space-bump);
    padding: var(--space-min);

    // gecko/mozilla
    list-style: none;

    // blink/webkit
    &::-webkit-details-marker {
      display: none;
    }

    .marker {
      --icon-color: rgba(var(--black-rgb), 0.3);

      transform: var(--rotate);
      transition: transform 200ms ease-in-out;
    }
  }

  .resource {
    &--details {
      padding: var(--space-min);
    }

    &--row {
      --icon-color: var(--text-link);
      --icon-element-outline: var(--icon-color);
      --button-display: flex;
      --svg-width: 1.25em;

      display: var(--button-display);
      align-items: center;
      justify-content: space-between;
      width: 100%;
      text-decoration: none;

      &--icon {
        font-size: 1.2em;
      }
      &--label {
        padding-right: 1em;
      }
    }
  }
}

.skill-level {
  &--low {
    --color: var(--green-darken-1);
    --icon-color: var(--color);
  }
  &--med {
    --color: var(--yellow-darken-1);
    --icon-color: var(--color);
  }
  &--high {
    --color: var(--red-base);
    --icon-color: var(--color);
  }

  .rpm-icontext--text {
    border-bottom: 1px solid var(--brand-gray-4);
  }
}

.rpm-tooltip--content {
  color: var(--fg);
  opacity: 0.7;
}

.MuiTooltip-popper > .MuiTooltip-tooltip {
  --fg: var(--tooltip-fg, var(--text-light));
  --bg: var(--tooltip-bg, var(--text-dark));

  background: var(--bg);
  margin: var(--space-small) var(--space-min);
  padding: var(--space-small);

  .MuiTooltip-arrow {
    color: var(--bg);
    overflow: unset;
  }

  ul {
    list-style-type: none;
    padding-left: 0;

    li {
      margin-bottom: var(--space-small);
    }
  }

  p {
    white-space: initial;
    text-transform: initial;
    text-align: left;
    line-height: var(--root-line-height);
  }
}
