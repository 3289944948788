.rpm-alert {
  position: sticky;
  top: 1em;
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: baseline;
  grid-gap: var(--space-small);
  text-align: left;
  padding: var(--space-small);
  font-size: 0.9em;
  border-radius: var(--radius-default);
  z-index: 1;

  &--error {
    background: var(--error);
    color: var(--text-light);
  }
  &--notice {
    background: var(--brand-black);
    color: var(--text-light);
  }

  &--icon {
    line-height: 0;
  }
}

* + .rpm-alert {
  margin-top: var(--space-default);
}
