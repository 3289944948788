.rpm-details-block {
  --button-text-decoration: none;

  position: relative;

  &.is-expanded {
    --details-max-height: none;
    --details-blend-opacity: 0;
  }

  &.is-expandable .details {
    position: relative;
    max-height: var(--details-max-height, var(--initial-height, 70px));
    overflow: hidden;
    margin-bottom: var(--space-xsmall);

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 2em;
      background: linear-gradient(to bottom, rgba(255, 255, 255, 0), var(--details-bg, var(--brand-gray-7)));
      opacity: var(--details-blend-opacity, 1);
      pointer-events: none;
    }
  }
}
