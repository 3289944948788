.dashboard-training-card {
  --row-icon-bg: var(--brand-base);
  --row-icon-fg: var(--white);

  margin-top: var(--space-default);
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  aspect-ratio: var(--aspect-ratio, 16/9);
  color: white;
  padding: var(--space-default) var(--space-default);
  background: linear-gradient(100deg, #000, transparent 65%),
    center / contain var(--bg-image);
  transform: scale(1);

  &.has-header {
    padding: var(--space-large) var(--space-default);
  }

  .rpm-marker {
    margin-bottom: var(--space-min);
  }

  &--header {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background-color: var(--brand-base);
    opacity: 0.9;
    text-align: center;
    padding: var(--space-min);
    width: 100%;
  }

  &--button {
    position: absolute;
    right: var(--space-default);
    bottom: var(--space-default);
  }

  &--video-cue {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.swiper-slide:not(.swiper-slide-active) {
  .dashboard-training-card {
    transform: scale(0.9);
    opacity: 0.6;
  }
}
