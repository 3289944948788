.activities {
  --sticky-top: 3.5em;

  &--list {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  &--icon {
    font-size: 1.75em;
  }

  &--row {
    --local-margin-top: 0;
    --button-fg: var(--text-dark);
    --button-font-weight: bold;
    --button-font-size: 1em;

    display: block;
    color: var(--button-fg);
    background: var(--user-row-bg, var(--text-light));

    + .activities--row {
      border-top: var(--user-row-border-bottom, 1px solid var(--brand-gray-6));
    }

    &--content {
      display: grid;
      grid-template-columns: auto 1fr;
      align-items: start;
    }

    &--avatar {
      display: flex;
    }

    &--details {
      --local-button-padding: var(--space-default) var(--space-bump)
        var(--space-default) 0;
    }
  }
}
