.persist-tour-summary {
  background: var(--brand-gray-0);
  color: var(--white);
  &--details {
    &--subtitle,
    &--time {
      margin: 0;
      margin-bottom: var(--space-default);
    }

    &--button {
      margin-top: var(--space-default);
      width: 100%;
    }

    .rpm-markdown {
      margin-bottom: 32px;
    }
  }

  &--video-player {
    margin-top: var(--space-default);
  }

  .dashboard-slider--swiper {
    margin-top: 16px;
    margin-bottom: 16px;
  }
}
