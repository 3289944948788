.workout-stats {
  margin-top: var(--space-min);

  &--item {
    --local-flex-gap: 0.5rem;
  }

  &--list {
    margin: var(--space-default);
    margin-bottom: 0;
  }
}
