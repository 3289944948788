.app-header {
  grid-area: header;
}

.app-menu {
  grid-area: menu;
}

.app-main {
  grid-area: main;
}

.app-footer {
  grid-area: footer;
}

.app-loading {
  grid-area: loading;
}

.app-loader {
  position: fixed;
  top: 25%;
  left: 50%;
  width: 6em;
  height: 6em;
  border-radius: var(--radius-large);
  z-index: 1000;
  text-align: center;
  pointer-events: none;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(var(--black-rgb), 0.1);
  font-size: var(--loader-font-size, 1em);
  opacity: var(--loader-opacity, 1);
  transition: opacity 600ms ease-in-out 80ms;
  transform: translate(-50%, -50%);

  &:not(.is-loading) {
    --loader-font-size: 0;
    --loader-opacity: 0;
  }
}

.app-header {
  position: relative;
  text-align: center;
  background-color: var(--brand-gray-0);
  color: var(--text-light);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3.5em;
  z-index: 1;

  .icon-pad {
    padding: 0 0.25em;
  }

  &--error {
    top: 3.5em;
    position: relative;
    display: block;
    height: auto;
    text-align: left;
    z-index: 100;
    max-width: 24em;
    margin: 0 auto;

    &--content {
      --local-bg: var(--brand-gray-1);
      --local-fg: var(--text-light);
      --button-width: 100%;
      --button-max-width: 24em;

      padding: var(--block-padding);
      border-radius: var(--error-border-radius, 0);
      position: relative;
      z-index: 1;
    }

    &::before {
      content: '';
      position: fixed;
      top: 3.5em;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
      background: var(--lounge-lizard);

      -webkit-backdrop-filter: blur(var(--backdrop-blur-small));
      backdrop-filter: blur(var(--backdrop-blur-small));
    }
  }

  &--label {
    text-transform: uppercase;
    font-family: var(--font-heading);
    font-weight: 500;
    font-size: 1em;
  }

  &--logo {
    display: inline-block;
    line-height: 0.7;

    svg {
      --logo-x: 8em;
    }
  }

  &--action {
    --button-position: absolute;
    --button-margin: 0;
    --button-radius: var(--radius-medium);
    --button-font-size: var(--text-size-small);
    --button-fg: var(--white);

    position: absolute;
    top: 50%;
    left: var(--space-bump);
    transform: translate(0, -50%);

    &--alt,
    &--dismiss {
      left: auto;
      right: var(--space-min);
    }

    &--alt {
      --icon-color: var(--brand-base);

      &[disabled] {
        opacity: 0.4;
      }
    }

    &--menu {
      --button-fg: var(--brand-gray-3);
      &--icon {
        font-size: 1.75em;
      }
    }

    &--back {
      --button-padding: 0 var(--space-default);
      --button-margin: 0 var(--negative-space-default);
    }

    &--activities {
      --button-position: relative;
      --button-padding: var(--space-xsmall);
      --icon-color: var(--text-light);
      --badge-edge: var(--brand-gray-0);
    }
  }
}

.app-content {
  footer {
    padding: var(--block-padding);
  }
}

.app-footer {
  flex: none;
  padding: var(--block-padding);
  text-align: var(--footer-text-align, center);
  background: var(--local-footer-bg, var(--brand-gray-0));
  color: inherit;
  font-size: 0.9em;

  > * {
    opacity: var(--local-footer-opacity, 0.6);
  }
}

.app-menu {
  --button-fg: var(--brand-gray-0);

  &--header {
    --button-padding: var(--space-xsmall) 0;

    flex: none;
    background: var(--white);
    margin: 0 var(--space-bump);
    border-bottom: 1px solid var(--brand-gray-6);
    z-index: 1;

    > * {
      display: block;
    }
  }

  &--content {
    flex: 1;
    overflow: auto;
  }

  &--section {
    --local-margin-top: 0;

    h4 {
      padding: var(--space-small) var(--space-bump);
      display: var(--list-item-display, grid);
      grid-template-columns: 1.5em 1fr;
      align-items: baseline;
      grid-gap: var(--space-min);
      background: var(--brand-gray-7);
      border-bottom: 1px solid var(--brand-gray-6);
    }

    ul {
      margin: 0;
      padding: var(--space-min) var(--space-bump);
      list-style: none;
    }

    li > * {
      padding: var(--space-small) 0;
      display: var(--list-item-display, grid);
      grid-template-columns: 1.5em 1fr auto;
      align-items: baseline;
      grid-gap: var(--space-min);
      text-decoration: none;
    }

    + .app-menu--section {
      border-top: 1px solid var(--brand-gray-6);
    }
  }

  .rpm-logo {
    --logo-x: 8em;
    margin: var(--space-small) 0;
  }
}
