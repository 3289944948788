.rpm-calendar {
  --day-fg: var(--brand-gray-3);
  --day-bg: var(--brand-gray-7);
  --bg: var(--white);
  --marker-color: var(--day-fg);
  --nutrition-marker-color: var(--brand-gray-3);
  --bg-stripes: repeating-linear-gradient(
    135deg,
    var(--brand-gray-6) 0,
    var(--brand-gray-6) 0.1em,
    transparent 0.1em,
    transparent 0.3em
  );

  user-select: none;
  position: var(--position, relative);

  .rpm-calendar--input-button {
    font-weight: normal;
  }

  .rpm-calendar--day-icons {
    position: absolute;
    bottom: 0;
    width: 100%;
  }

  .rpm-input--field {
    background: var(--local-dialog-bg);
  }

  &.is-open-mode,
  &.is-month-mode {
    z-index: 4;
  }

  &.is-short-month {
    --line-color: transparent;
    --toggle-pointer-events: none;
  }

  &.is-icon-mode {
    --bg: transparent;

    display: inline-block;
  }

  &--label {
    background-color: var(--calendar-label-bg, var(--bg));
  }

  &--controls,
  &--body {
    position: relative;
    background: var(--bg);
    z-index: 2;
  }

  &--controls {
    background: var(--bg);

    display: grid;
    grid-template-columns: 1fr auto 1fr;
    grid-gap: 1ch;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    height: 3em;
  }

  &--body {
    position: absolute;
    top: var(--local-top, 100%);
    left: 0;
    right: 0;
    border-top: 1px solid var(--border-color, var(--brand-gray-6));
    max-width: var(--calendar-width);
  }

  &--grid {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-template-rows: auto;

    > * {
      display: flex;
      align-items: center;
      position: relative;
      justify-content: center;
      white-space: nowrap;
      background: var(--day-bg);
      color: var(--day-fg);
      opacity: var(--opacity);
      overflow: hidden;
    }
  }

  &--control-bar {
    padding: var(--space-min);
  }

  &--days-of-week {
    padding-bottom: 2px;

    > * {
      font-size: var(--text-size-nudge);
      padding: var(--space-min) 0;
      text-transform: uppercase;
      opacity: 0.6;
    }
  }

  &--days {
    grid-gap: 2px;

    > * {
      border: none;
      padding: 0;
      margin: 0;
      font: inherit;
      text-transform: inherit;
      min-height: calc(var(--calendar-width, 100vw) / 7);

      &:not([disabled]) {
        &:hover,
        &:focus {
          --marker-color: var(--brand-base);
          --nutrition-marker-color: var(--brand-base);
          --day-fg: var(--brand-base);
          --day-bg: var(--brand-lighten-3);
          --opacity: 1;
          z-index: 1;
        }
      }
    }
  }

  [disabled] {
    cursor: not-allowed;

    &:not(.is-weekend) {
      opacity: 0.6;
    }
  }

  .is-weekend,
  .is-out-of-bounds {
    --day-fg: var(--brand-gray-4);
    --day-bg: var(--brand-gray-7) var(--bg-stripes);
  }

  .filler {
    --opacity: 0.5;
  }

  .is-today {
    --marker-color: var(--brand-base);
    --nutrition-marker-color: var(--brand-base);
    --day-fg: var(--brand-base);
    box-shadow: 0 0 0 2px var(--brand-base) inset;

    &:not(.is-selected) {
      --marker-size: 0.5em;
    }
  }

  .is-selected {
    --marker-color: var(--white);
    --nutrition-marker-color: var(--white);
    --day-fg: var(--white);
    --day-bg: var(--brand-base);
  }

  .has-event {
    // Triangle Corner Marker
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      border-width: var(--marker-size, 0.4em);
      border-style: solid;
      border-color: var(--marker-color) transparent transparent
        var(--marker-color);
    }
  }

  .has-nutrition {
    // Triangle Corner Marker
    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      border-width: var(--marker-size, 0.4em);
      border-style: solid;
      border-color: transparent transparent var(--nutrition-marker-color)
        var(--nutrition-marker-color);
    }
  }

  &--control {
    --line-color: var(--text-dark);

    border: none;
    background: transparent;
    padding: var(--space-small) 0;
    margin: 0;
    font: inherit;
    text-transform: uppercase;
    line-height: 1;
    font-weight: bold;
    color: var(--text-dark);
    display: flex;
    align-items: center;

    &:active:not(.rpm-calendar--input-button) {
      transform: scale(0.9);
    }

    &--previous {
      justify-content: center;
    }

    &--toggle {
      color: inherit;
      pointer-events: var(--toggle-pointer-events, all);
    }

    &--next {
      justify-content: center;
    }

    &--today {
      flex: none;
      position: absolute;
      top: 0;
      left: 0;
    }

    &--dismiss {
      flex: none;
      position: absolute;
      top: 0;
      right: 0;
    }

    &[disabled] {
      background: transparent;
    }
  }
}
