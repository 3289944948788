.rpm-tabset {
  &--tabs {
    --icon-color: var(--brand-base);

    position: sticky;
    top: 0;
    display: flex;
    justify-content: center;
    font-size: .8em;
    background: var(--white);
    box-shadow: 0 1px 0 0 var(--brand-gray-6);
    z-index: 1;

    li {
      flex: 1;
      position: relative;
      list-style: none;
      text-align: center;
      color: var(--brand-black);
      max-width: calc(var(--max-width) / var(--tab-count, 2));

      &:not(.is-active):not(.is-done) {
        >button {
          opacity: var(--opacity-ghost);
        }
        >progress {
          opacity: var(--opacity-ish);
        }
      }

      &.is-done:not(.is-active) {
        color: var(--brand-gray-3);
      }

      &.is-active:not(.has-progress) {
        box-shadow: 0 -.3em 0 0 inset var(--brand-base);
      }

      +li {
        border-left: 1px solid var(--brand-gray-6);
      }
    }

    button {
      font-size: inherit;
      font-family: var(--font-heading);
      font-weight: 900;
      color: var(--button-fg, inherit);
      background: var(--button-bg, transparent);
      border: var(--button-border, none);
      padding: var(--space-default) var(--space-small);
      text-transform: uppercase;
      margin: 0;
      display: block;
      width: 100%;
    }

    progress {
      -webkit-appearance: none;
      appearance: none;
      display: block;
      width: 100%;
      border: none;
      height: .3em;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      pointer-events: none;

      &::-webkit-progress-bar {
        background: var(--brand-gray-6);
      }

      &::-webkit-progress-value {
        background: var(--brand-base);
      }
    }
  }

  &--content {
    background: var(--local-bg, inherit);
    color: var(--local-fg, inherit);

    &:not(.is-active) {
      display: none;
    }
  }
}
