.rpm-picklist {
  --fg: var(--text-dark);
  --bg: var(--white);

  background: var(--bg);
  color: var(--fg);
  position: var(--position, relative);
  user-select: none;

  button:not(.rpm-button--shield) {
    font-size: inherit;
    font-family: inherit;
    color: inherit;
    padding: 0.75em;
    margin: 0;
    border: none;
    background: transparent;
    text-align: var(--text-align, left);
    cursor: pointer;
    width: var(--picklist-button-width, 100%);
  }

  &:not([open]) {
    --list-display: none;
  }

  &[open] {
    --max-height: 45vh;
    z-index: 4;
  }

  &--label {
    --text-align: center;

    background: var(--bg);
    color: inherit;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    line-height: 1;
    white-space: nowrap;
    z-index: 3;
    height: 3em;
  }

  &--marker {
    transition: 200ms ease-out;

    &--is-before {
      margin-left: -0.35em;
      margin-right: 0.35em;
    }

    &--is-after {
      margin-left: 0.35em;
    }
  }

  &--content {
    background: var(--bg);
    position: absolute;
    left: 0;
    right: 0;
    top: var(--local-top, 100%);
    max-height: var(--max-height, 40vh);
    margin: 0;
    padding: 0;
    list-style: none;
    display: var(--list-display, block);
    overflow: auto;
    z-index: 2;

    ul {
      margin: 0;
      padding: 0 0 0 3em;
      list-style: none;
    }

    li {
      --icon-color: var(--brand-gray-3);
      border-top: 1px solid var(--border-color, var(--brand-gray-6));
      color: var(--icon-color);

      &.is-selected {
        --icon-color: var(--text-dark);
      }

      button {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }

    // normalize kit icons in picklist options to verticaly align to the largest (FLY) icon
    .rpm-icon--kit {
      --svg-width: calc(var(--kit-icon-width-fly) * var(--kit-icon-scaler));
    }
  }
}
