.rpm-spinner {
  --spinner-size: 1;
  --local-line-color: var(--line-color, var(--brand-base));
  --local-line-alpha: var(--line-alpha, 1);
  --local-ring-color: var(--ring-color, var(--brand-gray-0));
  --local-ring-alpha: var(--ring-alpha, .1);
  --local-ring-size: var(--ring-size, 5);

  display: inline-flex;
  align-items: center;

  svg {
    width: 1em;
    height: 1em;
    border-radius: 50%;
    font-size: calc(var(--spinner-size) * 1em);

    +* {
      margin-left: .5ch
    }
  }

  .line {
    fill: none;
    stroke: var(--local-line-color);
    stroke-width: var(--local-ring-size);
    opacity: var(--local-line-alpha);
    stroke-linecap: round;
    transform-origin: 50% 50%;
    transform: rotate3d(0, 0, 1, 0deg);
    animation:
      2156ms spinner-arc ease-in-out infinite,
      1829ms spinner-rotate linear infinite;
  }

  .ring {
    fill: none;
    stroke: var(--local-ring-color);
    stroke-width: var(--local-ring-size);
    opacity: var(--local-ring-alpha);
  }
}

@keyframes spinner-rotate {
  to {
    transform: rotate3d(0, 0, 1, 360deg);
  }
}

@keyframes spinner-arc {
  from {
    stroke-dasharray: 0 150;
    stroke-dashoffset: 0;
  }

  to {
    stroke-dasharray: 100 150;
    stroke-dashoffset: -140;
  }
}
