.rpm-input {
  &--field {
    --input-shadow: 0 0 0 0;
    --input-border-color: var(--brand-gray-3);

    position: relative;
    display: var(--input-display, block);
    align-items: baseline;
    cursor: auto;
    width: 100%;
    color: var(--input-color, inherit);
    transition: 200ms ease-out;

    &:not(:first-child) {
      margin: var(--input-margin-top, var(--space-default)) 0 0 0;
    }

    &.has-error {
      --error-color: var(--input-error-color, var(--error));
      --input-placeholder-color: var(
        --input-placeholder-error,
        var(--error-color)
      );
      --input-label-color: var(--input-label-error, var(--error-color));
      --input-border-color: var(--input-border-error, var(--error-color));

      .rpm-input--box::after {
        content: '\f06a'; // FA icon via unicode entity: exclamation-circle
        font-family: var(--font-icon);
        color: var(--error);
        position: absolute;
        bottom: 0;
        right: 0;
        width: 1em;
        height: 1em;
        margin: 0 0.8em 1.2em 0;
        pointer-events: none;
      }
    }

    &.is-thin {
      --local-input-padding: var(--space-xsmall) var(--space-small);
    }

    &.use-block-label {
      --input-label-display: block;
      --input-label-text-align: left;
      --input-label-opacity: 1;
    }

    input,
    textarea,
    .rpm-calendar--input-button {
      font-size: inherit;
      line-height: inherit;
      font-family: var(--font-body);
      outline: none;
      border: var(--input-border, none);
      border-left: var(--input-border-left, var(--input-border));
      border-radius: var(--input-border-radius, var(--radius-default));
      padding: var(--local-input-padding, var(--input-padding));
      background-color: var(--input-background, var(--white));
      margin: 0;

      &:not([size]) {
        width: 100%;
      }

      &:focus {
        box-shadow: 0 0 0 1px var(--focus-color) inset;
      }

      &::-webkit-inner-spin-button {
        display: none;
      }

      &::placeholder {
        color: var(--input-placeholder-color, var(--brand-gray-3));
        font-size: 0.9em;
      }

      &[readonly] {
        cursor: default;
        color: inherit;
        background: var(--gray-lighten-6);
      }

      &[disabled] {
        cursor: not-allowed;
        color: var(--gray-darken-4);
        border-color: var(--gray-lighten-4);
        background: var(--gray-lighten-6);
      }
    }
  }

  &--label {
    display: var(--input-label-display, grid);
    grid-template-columns: auto auto 1fr;
    grid-gap: var(--space-default);
    font-size: var(--input-label-font-size, 0.85em);
    font-weight: var(--input-label-font-weight, inherit);
    color: var(--input-label-color, inherit);
    opacity: var(--input-label-opacity, 0.7);
    text-transform: uppercase;
    text-align: var(--input-label-text-align, inherit);
    margin-bottom: var(--space-min);
    line-height: var(--input-label-line-height, var(--root-line-height));
  }

  &--textarea {
    line-height: 0.5;

    textarea {
      line-height: var(--root-line-height);
    }
  }

  &--password-group {
    .peeker {
      padding: var(--local-input-padding, var(--input-padding));
      background: var(--peeker-bg, var(--brand-lighten-3));
      border: var(--input-border, none);
      border-left: var(--input-border-left, var(--input-border));
      border-radius: 0 var(--radius-default) var(--radius-default) 0;
    }
  }

  &--box {
    position: relative;
    display: var(--input-box-display, block);

    // @TODO - prefix handling needs to be systematized for dynamic prefix data (Jase, sprint 12)
    &.has-prefix {
      --input-box-display: grid;

      grid-template-columns: auto 1fr;
      align-items: center;

      input {
        padding-left: 2.5ch;
      }

      .rpm-input--prefix {
        display: inline-block;
        transform: translateX(2.5ch);
        margin-left: -1.5ch;
        pointer-events: none;
        color: var(--input-prefix-color, var(--text-dark));
      }
    }
  }

  &--help-message {
    display: block;
    padding: var(--space-small) var(--space-small) 0 var(--space-small);
    color: var(--ish);
  }

  &--error {
    display: block;
    padding: var(--space-small);
    color: var(--input-error-color);
  }
}

.use-thin-inputs {
  --local-input-padding: var(--space-xsmall) var(--space-small);
}
