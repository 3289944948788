.controls--hidden {
  .video-js {
    .vjs-control-bar,
    .vjs-big-play-button.vjs-big-play-button {
      display: none;
    }
  }
}

.video-js {
  --font-size: var(--text-size-small);
  --overlay-translate-x: -100%;
  --control-bar-translate-y: 0%;

  overflow: hidden;

  .vjs-poster {
    transform: translateZ(0);
  }

  .vjs-loading-spinner {
    margin: 0 !important;
  }

  &.vjs-layout-x-small {
    .vjs-control-bar {
      --font-size: var(--text-size-nudge);

      .vjs-progress-control {
        display: flex;
        height: 2.5em;
        top: unset;
      }
    }
  }

  .vjs-big-play-button.vjs-big-play-button {
    background-color: transparent;
    border: none;
    line-height: 0;

    &:hover,
    &:focus {
      background-color: transparent;
    }

    .vjs-icon-placeholder:before,
    .video-js .vjs-play-control .vjs-icon-placeholder:before {
      content: '';
    }
  }

  .vjs-progress-holder {
    box-shadow: none;

    &:hover,
    &:focus {
      box-shadow: none;
    }
  }
  .vjs-play-progress {
    background-color: #679d9e;

    &:before {
      content: '';
    }

    .vjs-time-tooltip {
      display: none;
    }
  }

  &.vjs-user-inactive,
  &.not-hover,
  &:not(:hover) {
    --overlay-translate-x: 0%;
    --control-bar-translate-y: 0%;
    .vjs-control-bar {
      opacity: 0 !important;
    }
  }

  &.vjs-layout-medium,
  &.vjs-layout-large {
    --font-size: var(--text-size-default);
  }

  .vjs-control-bar {
    max-width: 100%;
    display: grid;
    grid-auto-flow: column;
    grid-template-rows: auto 0.5em;
    grid-row-gap: var(--space-default);
    align-items: center;
    height: auto;
    line-height: 0;
    background-color: rgba(0, 0, 0, 0);
    // The translateZ here takes care of weird flickeriing that can occur
    transform: translateY(var(--control-bar-translate-y)) translateZ(0) !important; // ugh
    opacity: 1 !important;
    transition: opacity 250ms !important;
    z-index: 1;

    .vjs-control,
    .vjs-time-control {
      margin-right: var(--space-default);
      grid-row: 1;
    }

    .vjs-progress-control {
      position: relative;
      width: 100%;
      grid-row: 2;
      grid-column: 1 / span 10;
      background-color: rgba(0, 0, 0, 0);
      top: unset;
      height: 0.3em;
      transition: height 250ms, top 250ms;

      &:hover {
        top: unset;
        height: 2.5em;

        .vjs-marker {
          width: 0.5em;
        }
      }

      .vjs-marker {
        position: absolute;
        opacity: 1;
        height: 100%;
        background-color: var(--brand-base);
        width: 0.3em;

        display: unset;
        align-self: unset;
        justify-content: unset;
        border-radius: 0;
      }

      .vjs-progress-holder {
        margin: 0;
        height: 100%;
      }
    }

    .rpm-video--skip {
      &--backward {
        margin-left: var(--space-default);
      }

      svg {
        font-size: 2.5em;
      }
    }

    .vjs-audio-button {
      display: block;
      background: none;
    }

    .vjs-time-control {
      &.vjs-current-time {
        margin-right: 0;
        margin-left: 0;
      }
      &.vjs-time-divider {
        margin-right: 0;
        font-size: var(--font-size);
        height: 2.5em;
        min-width: unset;
      }
      &.vjs-current-time,
      &.vjs-time-divider,
      &.vjs-duration {
        background: none;
        display: flex;
        padding: 0;
      }
    }

    > :not(.vjs-play-control):not(.vjs-audio-button):not(.rpm-video--skip):not(
        .vjs-volume-panel
      ):not(.vjs-fullscreen-control):not(.vjs-progress-control):not(
        .vjs-time-control
      ),
    > .vjs-remaining-time {
      display: none;
    }
  }

  button {
    display: flex;
    align-self: center;
    justify-content: center;
    border-radius: 50%;
    padding: 0;
    margin: 0;
  }

  .vjs-control,
  .vjs-control .vjs-menu-button {
    font-size: var(--font-size);
    width: 2.5em;
    height: 2.5em;
    transition: 200ms ease-out;
    background: var(--lounge-lizard);
    cursor: pointer;

    &.vjs-mute-control > *,
    &.vjs-fullscreen-control > * {
      font-size: 2em;
    }

    &.vjs-play-control > * {
      font-size: 2em;
    }

    &.vjs-volume-panel {
      width: 2.3em !important; // ugh
      background: none;
    }

    &.vjs-audio-button .vjs-icon-placeholder {
      font-size: 1.8em;
    }

    &.vjs-volume-control.vjs-volume-horizontal {
      display: none;
    }

    > .vjs-icon-placeholder {
      flex: 1;
      display: flex;
      align-self: center;
      justify-content: center;

      &::before {
        position: initial;
        top: auto;
        left: auto;
        width: auto;
        height: auto;
        font-size: inherit;
        line-height: inherit;
      }
    }
  }
}

.vjs-overlay,
.video-js .vjs-overlay {
  display: flex;
  position: absolute;
  top: 0;
  left: 100%;
  right: auto;
  bottom: 0;
  height: auto;
  width: 100%;
  text-align: left;
  z-index: 1;
  padding: 0;
  transform: none;
}

.vjs-overlay-background {
  border-radius: 0;
  transform: none;
  background-color: none;
}

.workoutOverlay {
  display: flex;
}

#internal-overlay {
  display: flex;
  transition: var(--base-transition-timing) ease-out;
  transform: translateX(var(--overlay-translate-x, 0%));
  font-size: var(--text-size-default);
  background-color: var(--blue-blockers);
  color: var(--white);

  .expander {
    display: flex;
    flex-direction: column;

    button {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: var(--space-nudge);

      &::after {
        content: '';
        background: var(--white);
        width: 0.15em;
        height: var(--space-max);
        border-radius: 0.5em;
      }
    }
  }

  .workout {
    flex: 1;
    display: flex;
    transition: var(--base-transition-timing) ease-out;
    overflow: auto;

    &--collapsed {
      opacity: 0;
      max-width: 0;
      padding: var(--space-default) 0;
    }

    &--content {
      .rpm-markdown {
        display: inline;
      }
    }

    &--expanded {
      opacity: 1;
      padding: var(--space-default);
      padding-left: 0;
    }

    h1,
    h2,
    h3 {
      text-transform: uppercase;
      font-weight: 700;
    }

    h1 {
      font-size: var(--text-size-medium);
    }

    h2 {
      font-size: var(--text-size-bump);
    }

    ul {
      list-style: none;
      padding: var(--space-min) 0;
      margin: 0;
    }

    li {
      padding: var(--space-min) 0;
    }
  }

  .sections {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: var(--space-default);
    padding-left: 0;
    transition: var(--base-transition-timing) ease-out;
    position: relative;

    .cue {
      position: relative;
      flex: 1;
      --icon-color: var(--white);

      &.is-previous {
        --icon-color: var(--brand-base);
      }

      &:nth-child(2) {
        flex: 2;
      }

      &:last-child {
        flex: none;
      }

      &::before {
        content: '';
        position: absolute;
        top: 1.6em;
        left: 50%;
        bottom: 0.2em;
        width: 0.15em;
        background: var(--icon-color);
        border-radius: 0.5em;

        transform: translate(-50%, 0%);
      }
    }

    button {
      svg {
        font-size: 1.4em;
      }
    }
  }
}
