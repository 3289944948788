:root {
  // Brand Palette
  --brand-lighten-3: #d7ecec;
  --brand-lighten-2: #afd9da;
  --brand-lighten-1: #86c6c7;
  --brand-base: #5eb3b4;
  --brand-darken-1: #478687;
  --brand-darken-2: #2f5a5a;
  --brand-darken-3: #182d2d;

  --brand-gold: #edae7e;

  --brand-gray-7: #f6f7f8;
  --brand-gray-6: #e9e9ec;
  --brand-gray-5: #c6c6c9;
  --brand-gray-4: #a3a3a6;
  --brand-gray-3: #808083;
  --brand-gray-2: #616163;
  --brand-gray-1: #414142;
  --brand-gray-0: #222222;
  --brand-black: #111111;

  --accent-lighten-2: #fac8b7;
  --accent-lighten-1: #f59170;
  --accent-base: #f05a28;
  --accent-darken-1: #a03c1b;

  // Base Palette
  --aqua-lightest: #d7f3fc;
  --aqua-lighten-3: #b3e4f5;
  --aqua-lighten-2: #8acce6;
  --aqua-lighten-1: #61bad9;
  --aqua-base: #00a1c5;
  --aqua-darken-1: #157e99;
  --aqua-darken-2: #1b596b;
  --aqua-darken-3: #0d3340;

  --blue-lighten-3: #bcd3f5;
  --blue-lighten-2: #84a7db;
  --blue-lighten-1: #4d7cbf;
  --blue-base: #005b98;
  --blue-darken-1: #0c4675;
  --blue-darken-2: #10304f;
  --blue-darken-3: #071d30;

  --indigo-lighten-3: #d1c0ed;
  --indigo-lighten-2: #9a86bf;
  --indigo-lighten-1: #5d468c;
  --indigo-base: #221354;
  --indigo-darken-1: #1a0b42;
  --indigo-darken-2: #180b33;
  --indigo-darken-3: #0c061a;

  --purple-lighten-3: #e0b3e6;
  --purple-lighten-2: #a671ad;
  --purple-lighten-1: #814487;
  --purple-base: #571260;
  --purple-darken-1: #3d1342;
  --purple-darken-2: #2a0b2e;
  --purple-darken-3: #130514;

  --violet-lighten-3: #ffc2e5;
  --violet-lighten-2: #cc7aae;
  --violet-lighten-1: #b04d8c;
  --violet-base: #860f63;
  --violet-darken-1: #6e0b52;
  --violet-darken-2: #4d1739;
  --violet-darken-3: #330f26;

  --magenta-lighten-3: #ffbfd5;
  --magenta-lighten-2: #e685a5;
  --magenta-lighten-1: #d15981;
  --magenta-base: #b1185e;
  --magenta-darken-1: #911f49;
  --magenta-darken-2: #6b1e37;
  --magenta-darken-3: #4d1527;

  --red-lightest: #f5cccc;
  --red-lighten-3: #eb9999;
  --red-lighten-2: #e06666;
  --red-lighten-1: #d63333;
  --red-base: #cc0000;
  --red-darken-1: #990000;
  --red-darken-2: #660000;
  --red-darken-3: #330000;

  --sienna-lighten-3: #ffcabf;
  --sienna-lighten-2: #ff9d8c;
  --sienna-lighten-1: #ff7d66;
  --sienna-base: #ee5641;
  --sienna-darken-1: #bd422d;
  --sienna-darken-2: #993726;
  --sienna-darken-3: #73291d;

  --orange-lightest: #fae3bb;
  --orange-lighten-3: #fcdea4;
  --orange-lighten-2: #ffc26e;
  --orange-lighten-1: #ffa245;
  --orange-base: #fc7d2a;
  --orange-darken-1: #d66113;
  --orange-darken-2: #b34712;
  --orange-darken-3: #80330d;

  --yellow-lightest: #ffffcc;
  --yellow-lighten-3: #fcfcb1;
  --yellow-lighten-2: #fcf38b;
  --yellow-lighten-1: #fae35f;
  --yellow-base: #f5cc23;
  --yellow-darken-1: #e6b219;
  --yellow-darken-2: #cc9b1f;
  --yellow-darken-3: #a67a21;

  --green-lightest: #e9ffde;
  --green-lighten-3: #c9ffb3;
  --green-lighten-2: #a4f089;
  --green-lighten-1: #89db74;
  --green-base: #7fc465;
  --green-darken-1: #50a148;
  --green-darken-2: #358542;
  --green-darken-3: #296633;

  --mint-lighten-3: #b3f2e4;
  --mint-lighten-2: #80e0cb;
  --mint-lighten-1: #4ccfb2;
  --mint-base: #00b79b;
  --mint-darken-1: #159681;
  --mint-darken-2: #1b6e60;
  --mint-darken-3: #0b4d42;

  --gold-base: #a67b4f;

  // Grayscale
  --white: #ffffff;
  --gray-lighten-6: #f9fafb;
  --gray-lighten-5: #ebecee;
  --gray-lighten-4: #d8d9dc;
  --gray-lighten-3: #c5c7cb;
  --gray-lighten-2: #b2b5ba;
  --gray-lighten-1: #9fa3a9;
  --gray-base: #8d9299;
  --gray-darken-1: #787e86;
  --gray-darken-2: #636a73;
  --gray-darken-3: #4f5661;
  --gray-darken-4: #3c444f;
  --gray-darken-5: #2a323e;
  --gray-darken-6: #181b27;
  --black: #000000;
  --white: #ffffff;

  // RGB bits
  --brand-rgb: 94, 179, 180; // rgb(94, 179, 180);  // rgba(94, 179, 180, .1);
  --brand-gray-rgb: 128, 128, 131; // rgb(128, 128, 131); // rgba(128, 128, 131, .1);
  --accent-rgb: 255, 153, 0; // rgb(255, 153, 0);   // rgba(255, 153, 0, .1);
  --indigo-rgb: 34, 19, 84; // rgb(34, 19, 84);    // rgba(34, 19, 84, .1);
  --purple-rgb: 87, 18, 96; // rgb(87, 18, 96);    // rgba(87, 18, 96, .1);
  --violet-rgb: 134, 15, 99; // rgb(134, 15, 99);   // rgba(134, 15, 99, .1);
  --magenta-rgb: 177, 24, 94; // rgb(177, 24, 94);   // rgba(177, 24, 94, .1);
  --red-rgb: 204, 0, 0; // rgb(204, 0, 0);     // rgba(204, 0, 0, .1);
  --sienna-rgb: 238, 86, 65; // rgb(238, 86, 65);   // rgba(238, 86, 65, .1);
  --orange-rgb: 252, 125, 42; // rgb(252, 125, 42);  // rgba(252, 125, 42, .1);
  --yellow-rgb: 245, 204, 35; // rgb(245, 204, 35);  // rgba(245, 204, 35, .1);
  --green-rgb: 127, 183, 155; // rgb(127, 183, 155); // rgba(127, 183, 155, .1);
  --mint-rgb: 0, 183, 155; // rgb(0, 183, 155);   // rgba(0, 183, 155, .1);
  --aqua-rgb: 0, 161, 197; // rgb(0, 161, 197);   // rgba(0, 161, 197, .1);
  --blue-rgb: 0, 91, 152; // rgb(0, 91, 152);    // rgba(0, 91, 152, .1);
  --white-rgb: 255, 255, 255; // rgb(255, 255, 255); // rgba(255, 255, 255, .1);
  --gray-rgb: 141, 146, 153; // rgb(141, 146, 153); // rgba(141, 146, 153, .1);
  --black-rgb: 0, 0, 0; // rgb(0, 0, 0);       // rgba(0, 0, 0, .1);

  // Fixed RBGA bits
  --lounge-lizard: rgba(var(--black-rgb), 0.4);
  --lounge-lizard-light: rgba(var(--white-rgb), 0.4);
  --brand-awareness: rgba(var(--brand-rgb), 0.65);
  --blue-blockers: rgba(var(--black-rgb), 0.65);
  --dork-vader: rgba(var(--black-rgb), 0.85);
  --dork-maul: rgba(var(--red-rgb), 0.85);
  --three-pee-oh: rgba(var(--yellow-rgb), 0.85);
  --storm-stoopers: rgba(var(--white-rgb), 0.85);

  // Text Colors
  --text-light: var(--white);
  --text-subtle: var(--gray-lighten-4);
  --text-ish: var(--brand-gray-3);
  --text-dark: var(--brand-gray-0);
  --text-link: var(--brand-base);

  // Status Colors
  --info: var(--aqua-base);
  --info-fg: var(--aqua-darken-2);
  --info-edge: var(--aqua-base);
  --info-bg: var(--aqua-lightest);

  --success: var(--green-base);
  --success-fg: var(--green-darken-2);
  --success-edge: var(--green-base);
  --success-bg: var(--green-lightest);

  --caution: var(--yellow-base);
  --caution-fg: var(--yellow-darken-2);
  --caution-edge: var(--yellow-base);
  --caution-bg: var(--yellow-lightest);

  --warning: var(--orange-base);
  --warning-fg: var(--orange-darken-2);
  --warning-edge: var(--orange-base);
  --warning-bg: var(--orange-lightest);

  --danger: var(--red-base);
  --danger-fg: var(--red-darken-2);
  --danger-edge: var(--red-base);
  --danger-bg: var(--red-lightest);

  --error: var(--red-base);
  --error-fg: var(--red-darken-2);
  --error-edge: var(--red-base);
  --error-bg: var(--red-lightest);

  --alert: var(--red-base);
  --alert-fg: var(--white);
  --alert-edge: var(--red-darken-1);
  --alert-bg: var(--red-base);

  // Data Visualiztion and Chart Colors
  --chart-1: #00b79b;
  --chart-2: #124978;
  --chart-3: #00a1c5;
  --chart-4: #fbd65b;
  --chart-5: #fc7d2a;
  --chart-6: #6a144f;
  --chart-7: #e36471;
  --chart-8: #cde8c0;
  --chart-9: #178f7a;
  --chart-10: #5079ac;
  --chart-11: #5eb3d1;
  --chart-12: #b1185e;
  --chart-13: #ee5641;
  --chart-14: #ba779f;
  --chart-15: #a62d42;
  --chart-16: #b4dca2;
}
