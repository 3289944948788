body:has(.rpm-dialog.is-open) {
  overflow: hidden;
}

.rpm-dialog {
  --input-border: 0.07em solid var(--input-border-color, var(--brand-gray-3));
  --group-background: transparent;
  --local-dialog-fg: var(--text-dark);
  --local-dialog-bg: var(--brand-gray-7);

  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  z-index: 100;
  -webkit-backdrop-filter: blur(var(--local-backdrop-blur));
  backdrop-filter: blur(var(--local-backdrop-blur));
  background: var(--local-backdrop-bg, rgba(var(--black-rgb), 0));
  transition: var(--base-transition-timing) ease-in-out;

  &.ui-dark {
    --local-dialog-bg: var(--text-dark);
    --local-dialog-fg: var(--text-light);
  }

  &.is-open {
    --local-backdrop-bg: var(--dialog-open-bg, var(--lounge-lizard));
    --local-backdrop-blur: var(--backdrop-blur-min);
    --dialog-opacity: 1;
    --dialog-transform: translate(-50%, -50%) scale(1);
  }

  &.just-is {
    pointer-events: none;
  }

  &:not(.is-open) {
    --local-backdrop-blur: 0;
  }

  &.is-scrolling {
    --local-header-box-shadow: 0 0 1em -0.5em;

    .rpm-dialog--content {
      padding-top: 0.01rem;
    }

    .rpm-dialog--header {
      top: -0.01rem;
    }
  }

  &--box {
    flex: 1;
    position: absolute;
    top: 50%;
    left: 50%;
    padding: 0;
    background: var(--local-dialog-bg);
    color: var(--local-dialog-fg, --text-dark);
    z-index: 10;
    border-radius: var(--dialog-radius, 0.3em);
    width: var(--dialog-width, 25em);
    height: var(--dialog-height, auto);
    max-width: var(--dialog-max-width, 1024px);
    max-height: var(--dialog-max-height, 90vh);
    transform: var(--dialog-transform, translate(-50%, -50%) scale(0));
    opacity: var(--dialog-opacity, 0);
    transition: transform var(--base-transition-timing) ease-in-out,
      opacity var(--base-transition-timing) ease-in-out;
    text-align: left;
    display: flex;
    flex-direction: column;
    overflow: auto;
    will-change: opacity, transform;
  }

  &--header {
    --button-padding: 0;
    --button-margin: 0;
    --button-font-size: var(--text-size-small);
    --local-margin-top: 0;

    display: flex;
    align-items: center;
    justify-content: space-between;
    background: var(--dialog-header-bg, var(--local-dialog-bg));
    color: var(--dialog-header-fg, inherit);
    text-transform: uppercase;
    box-shadow: var(
      --dialog-header-box-shadow,
      var(--local-header-box-shadow, none)
    );
    position: sticky;
    top: 0;
    height: 55px;
    z-index: 3;

    h3 {
      font-family: var(--font-heading);
    }

    .rpm-dialog--dismiss {
      position: relative;
      margin: -0.5em 0;
    }
  }

  &--content {
    flex: 1;
    position: relative;

    > * {
      padding: var(--dialog-content-padding, var(--block-padding));
    }

    > footer {
      --group-background: none;
      --button-padding: 1em 2em;
      --group-gap: 1em;
    }
  }

  &--section {
    &--footer {
      --button-fg: var(--brand-gray-3);
      --local-margin-top: 0;

      text-align: center;
    }
  }

  &--footer {
    --button-fg: var(--brand-gray-3);
    --local-margin-top: 0;

    position: sticky;
    bottom: 0;
    background: linear-gradient(var(--local-dialog-bg), var(--white));
    border-top: 1px solid var(--text-subtle);
    text-align: center;
    z-index: 1;
  }

  &--dismiss {
    --button-fg: var(--brand-base);

    position: absolute;
    top: 0;
    right: 0;
    background: none;
    border: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &--confirmation {
    --button-fg: var(--brand-base);
  }

  &--error {
    --local-dialog-fg: var(--white);
    --local-dialog-bg: var(--brand-gray-1);
  }
}

@media (max-width: 694px) {
  .dashbaord--dialog--subscription {
    --dialog-width: 90%;
  }
}
