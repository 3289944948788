.nutrition {
  --dialog-max-width: 90%;
  .app-content--inner {
    --max-width: var(--header-width);
    padding-bottom: var(--space-bump);
  }

  background: var(--white);
  .nutrition--item {
    border: none;
  }

  .nutrition--toggle-indent {
    margin-left: var(--space-bump);
  }

  &--streak-bar {
    padding: var(--space-nudge) var(--space-bump);
    margin-bottom: var(--space-nudge);
  }

  .rpm-calendar--body {
    margin-left: calc(var(--padding-x) + var(--space-bump));
  }
}

.nutrition--item {
  --details-bg: var(--white);
  --item-width: calc(var(--header-width) - 2 * var(--space-bump));
  --item-height: calc(var(--item-width) * var(--video-height-ratio));
  &--image {
    background-image: var(--background-image-url);
    background-size: cover;
    background-repeat: no-repeat;
    height: var(--item-height);
    width: var(--item-width);
    position: absolute;
    top: 0;
    left: 0;
  }
  &--details {
    padding: var(--space-nudge) var(--space-bump) var(--space-nudge)
      var(--space-bump);

    a {
      text-decoration: underline;
    }
  }

  .rpm-hero {
    height: var(--item-height);
    width: var(--item-width);
    margin: var(--space-bump) var(--space-bump) 0 var(--space-bump);
    padding: 0;
  }

  .details--expander {
    --button-fg: var(--brand-base);
    --button-padding: 0 0 var(--space-bump) 0;
  }

  overflow: hidden;
  background: var(--white);
  border: 1px solid var(--brand-gray-6);
}
