.rpm-select-kit {
  .rpm-picklist--content {
    & ul {
      padding: 0;
      background: var(--brand-gray-7);

      &:not(:empty) {
        margin-top: 0;
      }

      li {
        padding-left: 3em;
      }
    }

    li {
      transition: var(--base-transition-timing) linear;
    }

    li[open] {
      --rotate: matrix(0, 1, -1, 0, 0, 0);
      color: var(--brand-gray-3);

      & > button .rpm-icontext {
        font-style: italic;
      }
    }

    .rpm-picklist--icons .marker {
      --icon-color: var(--brand-gray-3);
      transform: var(--rotate);
      transition: transform 0.2s ease-in-out;
    }
  }
}
