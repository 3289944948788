.rpm-color-picker {
  position: relative;

  &.is-open {
    --palette-display: grid;
  }

  &--label {
    display: block;
    font-size: .85em;
    color: var(--input-label-color, inherit);
    opacity: .7;
    text-transform: uppercase;
    margin-bottom: var(--space-min);
    line-height: var(--input-label-line-height, var(--root-line-height));
  }

  &--cue {
    --button-bg: var(--brand-gray-4);
  }

  &--palette {
    --button-padding: 1em;

    position: absolute;
    top: 0;
    right: 100%;
    z-index: 1;

    grid-template-columns: repeat(5, 2em);
    display: var(--palette-display, none);
    grid-gap: 1px;
    padding: var(--space-min);
    background: var(--brand-black);
    border-radius: var(--radius-default);

    >* {
      --button-bg: var(--bg);
      position: relative;

      &.is-none {
        --button-bg: linear-gradient(-45deg, white 45%, gray, white 55%);
        background-size: 25%;
      }

      &.is-selected {
        box-shadow: 0 0 0 .1em white;
        z-index: 1;
      }
    }
  }
}
