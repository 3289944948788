// see dialog styles, put any overrides in here
.rpm-submit-score {
  --input-border: 0.07em solid var(--input-border-color, var(--brand-gray-3));

  --input-group-items-border-left: none;
  --input-border-color: var(--brand-gray-3);
  --mentions-margin: var(--space-default) 0 0 0;
  --mentions-results-margin: 0;
  --group-align-items: baseline;
  --dialog-header-bg: var(--brand-gray-0);
  --dialog-header-fg: var(--white);
  --block-bg: var(--white);

  input:focus {
    border-color: var(--brand-base);
  }

  .rpm-input--toggle--switch {
    font-size: 0.9em;
  }

  .training {
    &--title {
      --local-margin-top: 0;

      display: flex;
      align-items: center;
      justify-content: space-between;
      font-weight: 600;
    }

    &--kit-select {
      --dialog-content-padding: 0 var(--space-bump);
      --group-margin-top: 0;
      --input-group-items-border-left: 0.1em solid var(--input-border-color);
      --input-padding: var(--space-nudge) var(--space-small);
      font-size: 0.9em;
    }

    &--movement {
      .demo-cue {
        display: none;
      }

      &--content li {
        grid-template-columns: var(--space-bump) 1fr;
      }
    }
  }

  .workout-section {
    border-top: 0.1em solid var(--brand-gray-6);
    border-bottom: 0.1em solid var(--brand-gray-6);
    padding: var(--space-small);

    &--group {
      margin-top: var(--space-default);
    }

    &--title {
      margin-bottom: var(--space-default);
    }

    &--container {
      border: 0.1em solid var(--brand-gray-6);
      border-radius: var(--radius-default);
      margin-top: var(--space-default);

      display: flex;
      flex-direction: column;
    }

    &--header {
      background-color: var(--brand-gray-6);
      padding: var(--space-min) var(--space-small);
      // display: grid;
      // flex-direction: row;
      // grid-template-columns: 0.25fr 1fr;
      // grid-gap: var(--space-small);
    }

    &--row {
      padding: var(--space-nudge);
      // display: grid;
      // flex-direction: row;
      // grid-template-columns: 0.25fr 1fr;
      grid-gap: var(--space-small);

      display: grid;
      grid-template-columns: 15% repeat(auto-fit, minmax(20%, 1fr));

      &--full {
        grid-template-columns: repeat(auto-fit, minmax(20%, 1fr));
      }

      div {
        align-self: center;
        text-align: left;
      }

      .rpm-input--field,
      .rpm-input--group {
        margin: 0;
      }
    }

    &--cell {
      display: flex;
      align-items: center;
      justify-content: left;

      &--full {
        align-items: unset;
        justify-content: unset;
      }
    }
  }

  &--perceived {
    border-top: 0.07em solid var(--brand-gray-6);

    &.disabled {
      --block-bg: var(--brand-gray-7);
    }

    .rpm-input--label {
      grid-gap: 0.25em;
    }

    &--labels,
    &--title {
      padding-bottom: var(--space-default);
    }

    .rpm-block {
      --local-padding: var(--space-default) var(--space-default);
      &.perceived--display {
        --local-padding: 0 0;
      }
    }

    &--details {
      &--text {
        --local-margin-top: var(--space-nudge);
      }
    }
  }

  &--feltLike {
    border: 0.07em solid var(--brand-gray-6);

    &.disabled {
      --block-bg: var(--brand-gray-7);
    }

    .rpm-input--label {
      grid-gap: 0.25em;
    }

    .rpm-block {
      --local-padding: var(--space-default) 0.5rem;
      &.feltLike--display {
        --local-padding: 0 0;
      }
    }

    .choices {
      --local-align-items: top;

      button {
        --local-button-justify: center;
        --button-timing: 0.1s;

        align-self: center;
        height: 1.75em;
        width: 1.75em;

        .rpm-icon {
          transition: none;
        }

        &.has-border {
          --local-button-fg: var(--brand-gray-5);
        }

        &.is-selected {
          --local-button-fg: var(--brand-base);
        }
      }
    }
  }

  .is-empty {
    .MuiSlider-mark {
      background-color: var(--brand-gray-5);
    }

    .MuiSlider-thumbColorPrimary {
      border: 3px solid var(--brand-gray-6);
    }
  }

  .MuiSlider-markLabel {
    font-size: var(--scale--1);
    opacity: var(--opacity-ish);
    color: var(--text-dark);
    padding-top: 2em;
    margin-top: -2em;
    font-weight: normal;
  }

  .MuiSlider-mark {
    height: 5px;
    width: 5px;
    background-color: var(--brand-base);
    border-radius: 50%;
  }

  .MuiSlider-thumb {
    &:hover {
      box-shadow: none;
    }
    &.Mui-active {
      box-shadow: none;
    }
  }

  .MuiSlider-thumbColorPrimary {
    background: var(--white);
    border: 3px solid var(--brand-base);
    width: 25px;
    height: 25px;
  }
  .MuiSlider-rail {
    background: var(--brand-gray-5);
  }
  .MuiSlider-track {
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(var(--brand-rgb), 0.75) 80%
    );
    border: none;
  }
}
