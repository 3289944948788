@keyframes zip {
  0% {width: 0%;padding: 1px;}
  75% {width: 100%;padding: 1px;}
  100% {padding: 0px;}
}

.rpm-zipper {
  display: flex;
  justify-content: center;
  position: absolute;
  top: var(--zipper-top, 100%);
  left: 0;
  width: 100%;

  &.is-active::after {
    --animation: zip ease-in 350ms forwards;
  }

  &::after {
    content: '';
    display: block;
    background: var(--brand-base);
    border-radius: var(--radius-default);
    animation: var(--animation, none);
  }
}
