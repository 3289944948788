.app-toasts {
  position: fixed;
  z-index: 99;
  margin: 0;
  padding: 0;
  width: 20em;
  max-width: 90vw;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);

  li {
    &.rpm-toast {
      &--danger {
        --local-toast-bg: var(--alert);
      }
      &--warning {
        --local-toast-bg: var(--caution);
      }
    }

    .rpm-toast--dismiss {
      --button-align-items: none;
      --icon-color: var(--text-dark);
    }

    .rpm-icontext--text {
      padding-left: var(--space-small);
    }


    --toast-x: 0%;
    --toast-y: -100%;
    --toast-scale: 0;
    --toast-opacity: 0;
    --toast-bg: var(--local-toast-bg, var(--brand-base));
    --toast-color: var(--local-toast-color, var(--text-dark));
    --icon-color: var(--local-toast-bg,  var(--brand-base));

    position: relative;
    display: grid;
    grid-template-columns: 1fr auto;
    grid-gap: 1ch;
    padding: var(--space-xsmall);
    background: var(--white);
    border-left: var(--toast-bg) 10px solid;
    opacity: var(--toast-opacity, 0);
    color: var(--toast-color);
    margin-top: var(--space-min);
    border-radius: var(--radius-bump);
    box-shadow: 0 .3em 1em -.5em var(--brand-black);
    height: var(--toast-height,  var(--height));
    transform-origin: center center;
    transform:
      translate(var(--toast-x, 0), var(--toast-y, -100%))
      scale(var(--toast-scale, 0));

    transition: var(--base-transition-timing) ease-in-out;
    transition-delay: var(--toast-delay, 0ms);

    -webkit-backdrop-filter: blur(var(--backdrop-blur-small));
    backdrop-filter: blur(var(--backdrop-blur-small));

    &.is-open {
      --toast-y: 0%;
      --toast-scale: 1;
      --toast-opacity: var(--local-opacity, 1);
      --toast-delay: calc(var(--queue, 0) * 300ms);
    }
  }
}
