.leaderboard {
  --filter-gap: 1px 0;

  display: flex;
  flex-direction: column;

  .leaderboard--filters {
    &--reset {
      --button-fg: var(--text-light);
      --button-font-size: var(--text-size-small);
      --icon-color: var(--brand-base);

      position: absolute;
      padding: var(--space-small);
      bottom: 0;
      left: 0;
      right: 0;
      display: flex;
      justify-content: space-between;
      align-items: baseline;
    }
  }

  &--content {
    --local-bg: var(--brand-gray-7);

    flex: 1;
  }

  &--infinite-spinner {
    text-align: center;
  }
}
