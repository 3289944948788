.rpm-tooltip {
  --fg: var(--tooltip-fg, var(--text-light));
  --bg: var(--tooltip-bg, var(--text-dark));

  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  left: var(--space-min);
  right: var(--space-min);
  top: var(--tooltip-top, auto);
  transform: translateY(calc(-100% - var(--space-nudge)));
  border-radius: var(--radius-default);
  max-width: 100%;
  background: var(--bg);
  color: var(--fg);
  padding: var(--space-default);
  z-index: 1;
  transition: var(--base-transition-timing) ease-out;
  will-change: transform;

  &--bubble {
    align-items: baseline;

    &.at-right {
      left: auto;
    }

    &.at-left {
      right: auto;
    }
  }

  &--content {
    --local-margin-top: var(--space-default);
  }

  &--message {
    margin: 0;
    font-size: .9em;
    opacity: .7;
  }

  &--dismiss {
    --button-fg: var(--brand-base);
    --button-font-size: var(--text-size-nudge);
  }

  &.has-arrow::after {
    content: '';
    position: absolute;
    top: calc(100% - 1px);
    right: var(--space-nudge);
    transform: translate(-50%, 0%);
    border: .5em solid transparent;
    border-top-color: var(--bg);
    border-bottom-width: 0;
  }

  &:not(.is-open) {
    display: none;
  }
}
