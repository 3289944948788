.mentions {
  --row-padding: var(--space-xsmall) var(--space-nudge);
  --row-bg: var(--gray-lighten-6);
  --button-fg: var(--text-dark);
  --button-display: flex;
  --button-padding: var(--row-padding);
  --button-line-height: 1;
  --button-timing: 0ms;

  position: relative;
  margin: var(--mentions-margin, 0);

  &--results {
    text-align: left;
    background: var(--row-bg);
    color: var(--button-fg);
    position: absolute;
    bottom: 100%;
    font-size: .9em;
    left: 0;
    right: 0;
    border-radius: var(--radius-bump);
    max-height: 35vh;
    overflow: auto;
    box-shadow: 0 0 0 .1em var(--gray-lighten-4), 0 1em 1em -1.25em;
    padding: var(--space-nudge);
    margin: var(--mentions-results-margin, 0);
    z-index: 1;

    button {
      gap: var(--space-nudge);
      width: 100%;
    }

    p {
      --local-margin-top: 0;
      margin: 0;
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      width: 100%;
    }

    li {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      border-top: 1px solid var(--border-top-color, rgba(0, 0, 0, 0.1));

      &:first-child {
        --border-top-color: transparent;
      }

      &.is-selected {
        --button-fg: var(--white);
        --border-top-color: transparent;

        border-radius: var(--radius-default);
        background: var(--text-link);

        + li {
          --border-top-color: transparent;
        }
      }

      &.no-results {
        --border-top-color: transparent;
        padding: var(--row-padding);
      }
    }
  }
}
