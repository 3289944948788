[role='button']:not(.MuiButtonBase-root) {
  cursor: pointer;
  outline: none;
  user-select: none;

  * {
    pointer-events: none;
  }
}

.rpm-button,
.MuiButton-root.MuiButtonBase-root {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  cursor: var(--button-cursor, auto);
  display: var(--button-display, inline-flex);
  align-items: var(--button-align-items, center);
  justify-content: var(--local-button-justify, center);
  border: var(--local-button-border, none);
  border-radius: var(--local-button-radius, var(--radius-default));
  position: var(--button-position, relative);
  overflow: var(--local-button-overflow, hidden);
  box-shadow: var(--local-button-edge, none);
  padding: var(--local-button-padding, var(--space-xsmall) var(--space-large));
  margin: var(--local-button-margin, 0 auto);
  color: var(--local-button-fg, inherit);
  background: var(--local-button-bg, none);
  background-size: cover;
  text-transform: var(--local-button-text-transform, inherit);
  text-decoration: var(--local-button-text-decoration, none);
  font: inherit;
  font-size: var(--local-button-font-size, unset);
  font-weight: var(--local-button-font-weight, 600);
  white-space: var(--local-button-white-space, nowrap);
  line-height: var(--local-button-line-height, inherit);
  text-align: var(--local-button-text-align, inherit);
  user-select: none;
  transition: color var(--button-timing, var(--base-transition-timing)) linear,
    background var(--button-timing, var(--base-transition-timing)) linear;

  * {
    pointer-events: none;
  }

  &:not([disabled]) {
    cursor: var(--button-cursor, pointer);
  }

  &[disabled] {
    --local-button-bg: var(--disabled-button-bg, var(--gray-base));
    opacity: var(--disabled-button-opacity, var(--opacity-ghost));
    cursor: not-allowed;
  }

  &--label {
    font-size: var(--local-button-font-size, inherit);
  }

  &--primary,
  &--secondary,
  &--accent,
  &--danger,
  &--cancel,
  &--simple,
  &--alternate {
    --local-button-font-size: var(--button-font-size, 0.9em);
    --local-button-font-weight: var(--button-font-weight, 600);
    --local-button-text-align: var(--button-text-align, center);
    --local-button-text-transform: var(--button-text-transform, uppercase);
    --local-button-padding: var(--button-padding, 1em 1.5em);
    --local-button-margin: var(--button-margin, 0 auto);
    --local-button-justify: var(--button-justify, center);
    --local-button-line-height: var(--button-line-height, inherit);
    --local-button-radius: var(--button-radius, var(--radius-default));

    width: var(--button-width, 100%);
    max-width: var(--button-max-width, none);
  }

  &--primary {
    --local-button-fg: var(--white);
    --local-button-bg: var(--brand-base);
  }

  &--secondary {
    --local-button-fg: var(--button-fg, var(--brand-gray-3));
    --local-button-bg: var(--button-bg, none);
    --local-button-edge: 0 0 0 0.1em var(--brand-gray-6) inset;
  }

  &--accent {
    --local-button-fg: var(--white);
    --local-button-bg: var(--accent-base);
  }

  &--cancel {
    --local-button-fg: var(--brand-gray-3);
    --local-button-bg: transparent;
    --local-button-edge: 0 0 0 0.1em var(--brand-gray-6) inset;
  }

  &--danger {
    --local-button-fg: var(--white);
    --local-button-bg: var(--danger);
  }

  &--simple {
    --local-button-fg: var(--button-fg, inherit);
    --local-button-bg: var(--button-bg, none);
    --local-button-edge: var(--button-edge, none);
  }

  &--alternate {
    --local-button-fg: var(--button-fg, var(--brand-base));
    --local-button-bg: var(--button-bg, rgba(var(--black-rgb), 0.3));
    --local-button-edge: var(--button-edge, 0 0 0 1px inset);
  }

  &--link,
  &--text,
  &.MuiButton-text,
  &--icon,
  &--secret,
  &--block,
  &--shield {
    --local-button-white-space: normal;
    --local-button-radius: var(--button-radius, none);
    --local-button-font-size: var(--button-font-size, 1em);
    --local-button-font-weight: var(--button-font-weight, 400);
    --local-button-line-height: var(--button-line-height, inherit);
    --local-button-justify: var(--button-justify, start);
    --local-button-fg: var(--button-fg, inherit);
    --local-button-bg: var(--button-bg, none);
    --local-button-padding: var(--button-padding, 0);
    --local-button-margin: var(--button-margin, 0);
    --disabled-button-bg: none;
    --local-button-overflow: var(--button-overflow, visible);
  }

  &--link {
    --local-button-fg: var(--button-fg, var(--brand-base));
    --local-button-text-decoration: var(--button-text-decoration, underline);
  }

  &--text,
  &.MuiButton-text {
    --local-button-justify: var(--button-justify, start);
  }

  &--icon {
    --disabled-button-opacity: var(--opacity-ish);
  }

  &--secret {
    --button-line-height: 0;
    --disabled-button-opacity: 1;
  }

  &--block {
    --button-display: block;
    --button-line-height: inherit;
    --disabled-button-opacity: 1;
    --local-button-padding: var(--button-padding, var(--block-padding));
    --local-button-font-weight: normal;
    --local-button-overflow: var(--button-overflow, hidden);

    width: var(--button-width, 100%);
  }

  &--shield {
    --button-bg: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.25),
      rgba(0, 0, 0, 0.75)
    );
    --button-position: fixed;
    --button-cursor: default;

    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: var(--button-width, 100%);
    z-index: 1;
  }

  &--small {
    --local-button-font-size: var(--text-size-small);
  }

  &--thin {
    --local-button-padding: var(--button-padding, 0.65em 1em);
  }

  &--skinny {
    --local-button-padding: var(--button-padding, 0.3em 1em);
  }

  &--outlined {
    --local-button-edge: 0 0 0 1px inset;
    --local-button-fg: var(--local-button-bg);

    background: none;
  }
}
