.journal {
  --search-icon-left: 0;
  --search-input-padding-left: 3.5rem;
  --search-input-padding-right: 5rem;
  --journal-filters-padding: var(--block-padding);
  --input-border: unset;

  .app-main {
    background-image: var(--content-bg, none);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom center;
  }

  .rpm-dialog--confirmation {
    --dialog-max-width: 90%;
  }

  .rpm-input--control--search {
    height: 52px;

    #search {
      height: 52px;
      box-sizing: border-box;
      border: 1px solid transparent;

      &:focus {
        box-shadow: none;
        border: 1px solid var(--focus-color);
        border-radius: 0;
      }
    }
  }

  .rpm-input--search--icon {
    line-height: inherit;
    transform: unset;

    .beta-flag {
      transform-origin: top;
      position: absolute;
      transform: translateX(-50%) rotate(-90deg);
      left: 0;
      width: 52px;
      padding: 2px 0;
    }

    .rpm-icon {
      position: absolute;
      left: 2rem;
    }
  }

  &--filters {
    --local-top: unset;
    --search-grid-row: 1fr;
    --filter-cols: 1;

    background-color: var(--brand-gray-6);

    &--advanced {
      --grid-columns: auto auto;
      --calendar-label-bg: var(--brand-gray-6);

      .rpm-calendar--body {
        top: unset;
        left: 0;
        right: 0;
      }

      row-gap: var(--space-small);
      padding: var(--journal-filters-padding);
    }

    &--chips {
      overflow-x: scroll;
    }

    &--reset {
      --button-fg: var(--text-light);
      --button-font-size: var(--text-size-small);
      --icon-color: var(--brand-base);

      position: absolute;
      padding: var(--space-small);
      bottom: 0;
      left: 0;
      right: 0;
      display: flex;
      justify-content: space-between;
      align-items: baseline;
    }
  }

  &--subheader {
    --icon-color: var(--brand-base);
    padding: var(--space-small) var(--space-bump);
    align-items: center;
  }

  &--no-results {
    background-color: var(--lounge-lizard-light);
    text-align: center;
  }

  &--row {
    --local-margin-top: 0;
    --button-fg: var(--text-dark);
    --button-align-items: center;
    --row-bg: var(--white);

    position: relative;
    padding: var(--block-padding);
    width: 100%;
    display: grid;
    grid-template-columns: var(--row-columns, 3em 1fr auto);
    align-items: var(--row-align-items, baseline);
    grid-gap: var(--space-small);

    .background-button {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }

    a {
      text-decoration: none;
    }

    &--score {
      text-align: right;
      font-size: 0.9em;
      line-height: 1;

      small {
        font-size: var(--text-size-nudge);
      }
    }

    + .journal--row {
      border-top: 1px solid var(--brand-gray-6);
    }
  }
}
