.rpm-card-deck {
  position: relative;
  list-style: none;
  display: var(--card-deck-display, flex);
  margin: var(--card-deck-margin, 0 var(--negative-space-bump));
  padding: var(--card-deck-padding, 0);
  overflow-x: var(--card-deck-overflow-x, auto);
  overflow-y: var(--card-deck-overflow-y, hidden);
}

.rpm-card {
  --local-margin-top: 0;

  flex: var(--card-flex, none);
  width: var(--card-width, 40vw);
  max-width: var(--card-max-width, 50vw);
  white-space: var(--card-white-space, normal);
  padding: var(
    --card-padding,
    var(--space-min) var(--space-bump) var(--space-default) var(--space-bump)
  );
  margin: var(--card-margin, 0);
  font-size: var(--text-size-default);

  &--product {
    .rpm-flipcard {
      margin-bottom: var(--space-min);
    }
    &--back {
      background: var(--brand-gray-7);
      width: 100%;
      height: 100%;
      align-items: center;

      .code-section {
        display: flex;
        height: 100%;
        align-items: center;
        justify-content: center;
        text-align: center;
        width: 85%;
      }

      .bottom-section {
        width: 100%;
        padding: var(--space-min);
      }
    }
    .rpm-card--product--copy {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }

    .rpm-card--product--close {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }

    .rpm-input--field {
      input {
        --focus-color: transparent;
        border-color: var(--brand-gray-4);
        border-width: 1px 1px 0px 1px;
        border-style: solid;
        text-align: center;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }

      & input[readonly] {
        background: var(--white);
      }
    }
  }

  &--image-container {
    position: relative;
    border-radius: var(--radius-default);
    overflow: hidden;
  }

  &--marker {
    --marker-color: var(--brand-gray-3);
    position: absolute;
    top: 0;
    left: 0;
    width: 25%;
    line-height: 1;

    &--text {
      position: absolute;
      top: 5px;
      left: 5px;
      color: var(--white);
      width: 50%;
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      border-width: var(--marker-size, 2.25em);
      border-style: solid;
      border-color: var(--marker-color) transparent transparent
        var(--marker-color);
      opacity: var(--opacity-ghost);
    }
  }

  &--content {
    display: flex;
    flex-direction: column;
    height: 100%;
    text-decoration: none;
  }

  &--image {
    width: 100%;
    height: auto;
    line-height: 0;
  }

  &--text {
    font-size: var(--text-size-small);
  }
}
