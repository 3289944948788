.dashboard {
  --user-avatar-scale: 1.1;

  .rpm-dialog--confirmation {
    --dialog-max-width: 90%;
  }

  *--sections {
    grid-area: content;
  }

  &-item {
    display: grid;
    grid-template-columns: 1fr auto;
    padding: var(--block-padding);
    .journal--row {
      --block-padding: 0;
    }
    .rpm-icontext--text {
      .ghost {
        font-weight: normal;
        text-transform: none;
      }
    }
  }

  &--promotion {
    grid-area: promo;
  }

  &--loading {
    text-align: center;
    padding: 0;
    font-size: 0;
    transition: padding 0.4s ease-in-out, font-size 0.4s ease-in-out;

    &.is-visible {
      padding: 1em 0;
      font-size: 1em;
    }
  }

  &--my-user-row {
    --user-row-action-align: center;
  }

  &--sections {
    --user-row-border-bottom: none;

    background: var(--white);

    a {
      text-decoration: none;
      &.underline {
        text-decoration: underline;
      }
    }
  }

  &--details {
    border-bottom: 1px solid var(--brand-gray-6);
    background: var(--brand-gray-7);
    &--description {
      --local-margin-top: var(--space-nudge);
      padding: var(--space-min) 0 var(--space-bump) 0;

      .rpm-markdown {
        margin-top: var(--local-margin-top, var(--space-default));
        margin-bottom: var(--local-margin-top, var(--space-default));
      }
    }

    &--padding {
      padding: var(--block-padding);
    }

    &--button {
      &.is-open {
        transform: rotate(90deg);
      }
      transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
    }
  }

  &--section {
    --button-fg: var(--text-dark);
    border-top: 1px solid var(--brand-gray-6);
    overflow: hidden;

    &--title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: var(--block-padding);
      background: var(--brand-gray-7);
      border-bottom: 1px solid var(--brand-gray-6);
      font-weight: 900;
    }

    &--training {
      --row-icon-bg: var(--brand-base);
      --row-icon-fg: var(--text-light);

      .dashboard-slider:not(:last-child) {
        border-bottom: var(--brand-gray-7) 8px solid;
        padding-bottom: var(--space-min);
      }

      margin-bottom: var(--space-min);
    }

    &--affiliates {
      --row-icon-bg: var(--brand-gray-1);
      --row-icon-fg: var(--text-light);
      --row-align-items: center;
    }

    &--nutrition {
      .nutrition--item {
        --item-width: min(
          calc(var(--header-width) - 2 * var(--space-bump)),
          414px
        );
      }
    }

    &--workshops {
      --row-icon-fg: var(--text-light);
      --row-align-items: center;

      .workshop--item {
        --horizontal-margin: 0;
        --header-width: var(--dashboard-slider--slide-width);

        --item-width: var(--dashboard-slider--slide-width);

        border: none;
      }
      .journal--row--details {
        p {
          .rpm-icontext {
            padding-right: var(--space-min);
          }
        }
      }

      .rpm-marker {
        &.has-image {
          background-size: var(--avatar-bg-size, cover);
          background-repeat: no-repeat;
          background-position: center center;
        }
      }
    }
  }

  &--promotion {
    --local-margin-top: 0;
    --header-padding: 0;
    --swiper-pagination-color: var(--brand-base);
    --header-ratio: 1.25;
    --header-width: 100vw;
    --header-height: calc(var(--header-width) * var(--header-ratio));

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;

    &.is-playing {
      position: relative;
    }

    &--button {
      font-size: 5vmin;
    }

    .swiper {
      height: var(--header-height);
      width: var(--header-width);
      .swiper-slide {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        padding: var(--space-bump) var(--space-bump) var(--space-default)
          var(--space-bump);

        .dashboard--promotion--image {
          position: absolute;
          top: 0;
          left: 0;
          z-index: -1;
          width: var(--header-width);
          height: var(--header-height);

          .promo-image {
            width: var(--header-width);
            height: var(--header-height);
            background-image: linear-gradient(100deg, #000, transparent 65%),
              var(--promo-image-url);
            z-index: -1;
            background-size: cover;
            background-position: 50% 40%;
          }
        }

        .dashboard--promotion--title {
          margin-top: var(--space-default);
        }

        .dashboard--promotion--subtitle {
          margin-top: var(--space-small);
        }
      }
      .swiper-pagination {
        pointer-events: none;
        .swiper-pagination-bullet {
          pointer-events: all;
        }
      }
    }

    .brightcove-react-player-loader {
      width: var(--header-width);
      height: var(--header-height);
    }

    // &--title {
    //   font-size: var(--text-size-xlarge);
    // }

    &--subtitle {
      // line-height: 1.2;
      font-size: var(--text-size-bump);
      text-transform: none;
    }

    &--coach {
      --local-margin-top: var(--space-min);
      font-size: var(--text-size-default);
      font-weight: bold;

      small {
        display: inline-grid;
        grid-template-columns: var(--space-default) 1fr;
        grid-gap: var(--space-min);
        align-items: var(--local-align-items, center);
      }

      .rpm-icon {
        justify-self: center;
      }
    }

    &--button {
      --button-margin: 0;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  &--dialog {
    section:first-of-type {
      padding-top: 0;
    }
  }

  &--panel {
    &--10k {
      section {
        overflow: auto;
      }

      img {
        width: 100%;
        height: auto;
        line-height: 0;
        margin: 0;
        padding: 0;
      }
    }
  }
}

.dashboard--featured-products {
  .rpm-featured-products {
    padding: 0;

    .rpm-card-deck {
      padding-left: var(--space-default);
    }
  }
}
