.team-panel {
  --local-border-width: 1px;
  --input-border: .07em solid var(--text-link);
  --input-border-color: var(--brand-gray-4);
  --user-row-bg: var(--white);

  &--invites {
    --sticky-top: 3.5em;

    .app-panel--body {
      display: flex;
      flex-direction: column;
    }

    .invites {
      &--search--results {
        flex: 1;
        display: none;

        &.is-open {
          display: block;
        }
      }

      &--pending {
        display: flex;
        flex-direction: column;
      }

      &--pending--list {
        flex: 1;
      }

      &--email-form {
        display: none;

        &.is-open {
          display: block;
        }
      }
    }
  }

  .user--meta--raised {
    --icon-color: var(--orange-base);
  }

  .team-avatar {
    --icon-color: var(--brand-gray-5);
    --local-padding: var(--space-bump) 0 var(--space-default) 0;
  }
}
