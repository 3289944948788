.edit-profile {
  --local-bg: var(--brand-gray-7);

  .tab-content-affiliate {
    --icon-color: var(--brand-gray-5);
  }

  .profile {
    --local-padding: var(--block-padding);

    &--welcome,
    &--logout {
      text-align: center;
    }

    &--footer {
      margin-top: var(--space-default);
    }

    &--level-text {
      --bg: var(--brand-gray-1);
      --fg: var(--white);

      padding: var(--space-nudge);
      border-radius: var(--radius-bump);
      font-size: 0.8em;
      background: var(--bg);
      color: var(--fg);
      margin-top: var(--space-default);
      position: relative;

      &.at-level {
        &--0 {
          --border-size: 0;
        }
        &--1 {
          --x-shift: 12.5%;
        }
        &--2 {
          --x-shift: 37.5%;
        }
        &--3 {
          --x-shift: 62.5%;
        }
        &--4 {
          --x-shift: 87.5%;
        }
      }

      &.at-kit,
      &.at-goal {
        &--0 {
          --border-size: 0;
        }
        &--1 {
          --x-shift: 16.5%;
        }
        &--2 {
          --x-shift: 49.5%;
        }
        &--3 {
          --x-shift: 82.5%;
        }
      }

      &::after {
        content: '';
        position: absolute;
        bottom: 100%;
        left: var(--x-shift);
        border: var(--border-size, 0.5em) solid transparent;
        border-bottom-color: var(--bg);
        transform: translate(-50%, 0%);
        transition: var(--base-transition-timing) ease-out;
      }
    }
  }

  .rpm-input--radio {
    border: var(--input-border);
  }
}
